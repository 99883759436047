import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { ISaleProduct, ISaleProducts } from 'src/type/sales/products'
import { cleanParamsAPI } from 'src/utils'

interface IProps {
  data?: ISaleProducts
  isLoading: boolean
  fetchSaleProducts: (pageIndex: number, pageSize: number, params?: Object) => void
  getParams: () => Object
  handleChangeParams: (pageIndex: number, pageSize: number, params?: Object) => void
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: 'Product name',
    className: 'min-w-100px',
  },
  {
    label: 'Category',
    className: 'min-w-100px',
  },
  {
    label: 'Construction mode',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-100px pe-5',
  },
]

const ProductTable = ({
  data,
  isLoading,
  fetchSaleProducts,
  getParams,
  handleChangeParams,
  checkedList,
  toggleCheck,
  toggleCheckAll,
  isCheckedAll,
}: IProps) => {
  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    const cleanedParams = cleanParamsAPI(getParams())

    handleChangeParams(pageIndex, pageSize, cleanedParams)
    fetchSaleProducts(pageIndex, pageSize, cleanedParams)
  }

  return (
    <div className='card-body py-4'>
      <SappTable
        headers={headers}
        loading={isLoading}
        data={data?.products}
        isCheckedAll={isCheckedAll}
        hasCheckAll={true}
        classNameSelect={'d-flex justify-content-center'}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {isLoading ? (
          <LoadingTable headers={headers} />
        ) : (
          <>
            {data?.products?.map((product: ISaleProduct, index: number) => {
              const isChecked = checkedList.includes(product?.id)

              return (
                <tr key={product?.id}>
                  <td className='text-center w-50px px-0'>
                    <SAPPCheckbox
                      checkTarget='.form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(product?.id!)
                      }}
                      className='justify-content-center'
                    />
                  </td>
                  <td className='text-start'>{product.name}</td>
                  <td className='text-start'>{product.course_category?.name}</td>
                  <td className='text-start'>{product.construction_mode}</td>
                  <td className='text-start'>{product.status}</td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={data?.metadata?.page_index || 1}
        pageSize={data?.metadata?.page_size || 10}
        totalItems={data?.metadata?.total_records || 0}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default ProductTable
