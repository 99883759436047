import { useForm } from 'react-hook-form'
import HookFormTextField from '../../base/textfield/HookFormTextField'
import HookFormEditor from '../../base/editor/HookFormEditor'
import { RESOURCE_LOCATION } from '../../base/upload-file/ModalUploadFile/UploadFileInterface'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { VALIDATE_NUMBER, VALIDATION_FIELD } from 'src/constants'
import { GradingDetail } from 'src/type/grade'
import { COMMENTS } from 'src/constants/grade'
import { isUndefined } from 'lodash'
import { formatGrade } from 'src/utils/grade'

interface SubmitFormProps {
  item: GradingDetail
  onSubmit: (data: { grade: string; comment: string }) => void
  onCancel: () => void
  isFile: boolean
  loading?: boolean | undefined
}

const SubmitForm = ({ item, onSubmit, onCancel, isFile, loading }: SubmitFormProps) => {
  const isComment = item?.validate === 'comment' || (isUndefined(item?.validate) && item?.comment)
  const isGrade =
    item?.validate === 'grade' ||
    (isUndefined(item?.validate) && item?.grade && item.grade !== 0) ||
    isFile

  const validateNumber = z
    .string()
    .regex(/^[0-9]*$/, VALIDATE_NUMBER)
    .transform((val) => parseInt(val))
    .refine((val) => val >= 0, { message: VALIDATE_NUMBER })
    .or(z.number().int(VALIDATE_NUMBER).min(0, { message: VALIDATE_NUMBER }))

  const validationSchema = z.object({
    comment:
      isComment && !isFile
        ? z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD)
        : z.any(),
    grade: isGrade ? validateNumber : z.any(),
  })

  const { control, handleSubmit } = useForm<{ grade: string; comment: string }>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  return (
    <div
      className='grade-comment-detail grade-box'
      key={Number(item?.begin) + Number(item?.end)}
      data-begin={item?.begin}
      data-end={item?.end}
      tabIndex={0}
    >
      {!isFile && (
        <div className='sapp-mb-24px'>
          <HookFormEditor
            height={300}
            placeholder=''
            label={`${COMMENTS.Comment}:`}
            name='comment'
            control={control}
            className='sapp-comment-box w-100 fs-6'
            math={true}
            defaultValue={item.comment}
            resourceLocation={RESOURCE_LOCATION.QUIZ}
            object_id={null}
            setDataFile={() => {}}
            required={!!isComment}
            isGradeForm={true}
            is_resource={false}
          />
        </div>
      )}
      <div className='sapp-mb-24px'>
        <HookFormTextField
          className='sapp-grade-box'
          required={isGrade || isFile}
          control={control}
          name='grade'
          type='number'
          placeholder=''
          label={`${COMMENTS.Grade}:`}
          isListScreen
          defaultValue={item?.grade || Number(item?.grade) === 0 ? formatGrade(item?.grade) : null}
          requiredZero={false}
          minNumber={0}
          maxNumber={100}
        />
      </div>
      <SAPPDialogButtonsCancelSubmit
        cancelClick={onCancel}
        cancelButtonCaption='Cancel'
        classNameCancel='button-cancel'
        okButtonCaption='Save'
        okOnClick={handleSubmit(onSubmit)}
        className='m-0 grade-box-action d-flex justify-content-between align-items-center'
        loading={loading}
      />
    </div>
  )
}

export default SubmitForm
