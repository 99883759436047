import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OrderAPI } from 'src/apis/sales/orders'
import LoadingTable from 'src/common/LoadingTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import Search from 'src/components/base/search'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import { PageLink } from 'src/constants'
import { TITLES_ORDER_STATUS } from 'src/constants/sales'
import { IOrder, IOrders } from 'src/type/sales/orders'
import { IPromotionCode } from 'src/type/sales/promotionCodes'
import { formatPrice } from 'src/utils'

const headers = [
  {
    label: '#',
    className: 'min-w-30px px-2 text-center',
  },
  {
    label: 'Product',
    className: 'min-w-100px pe-5 ',
  },
  {
    label: 'Customer',
    className: 'min-w-100px pe-5 ',
  },
  {
    label: 'Deal ID',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Original Price',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Final Price',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Status',
    className: 'w-150px pe-5',
  },
]

interface IProps {
  promotionCode: IPromotionCode | null
}

const OrderDetail = ({ promotionCode }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<IOrders | null>(null)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [searchValue, setSearchValue] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    fetchOrders(pageIndex, pageSize)
  }, [pageIndex, pageSize])

  const fetchOrders = useCallback(
    async (pageIndex: number, pageSize: number, params?: Object) => {
      setIsLoading(true)
      try {
        const res = await OrderAPI.getOrders({
          page_index: pageIndex,
          page_size: pageSize,
          params,
        })
        setData(res?.data)
      } catch (error) {
        // do nothing
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, setData]
  )

  const handleSearchChange = (e: any) => {
    setSearchValue(e.target?.value ?? '')
  }

  const handleSearch = () => {
    fetchOrders(pageIndex, pageSize, {
      name: searchValue,
    })
  }

  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    setPageIndex(pageIndex)
    setPageSize(pageSize)
  }

  return (
    <div className='card mb-5 mb-xl-10 flex sapp-min-h-100vh'>
      <div className='py-8 px-10 '>
        <div
          className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${
            data?.metadata?.total_records ? ' mb-5' : ''
          }`}
        >
          <div className='sapp-flex-1 d-flex align-items-center'>
            <Search
              showSearch={true}
              onChange={handleSearchChange}
              placeholder={'Search order'}
              smallSearch={true}
              onSubmit={handleSearch}
              isListScreen
            />
            <ButtonPrimary
              title='Search'
              onClick={() => {
                handleSearch()
              }}
              className='ms-4'
              loading={isLoading}
              size='small'
            />
          </div>
          <div className='d-flex justify-content-between'>
            <ButtonIconPrimary
              iconName={'plus'}
              title={'Add Order'}
              onClick={() => navigate(`${PageLink.OPERATIONS_SALES_ORDERS}/create`)}
              size='small'
            />
          </div>
        </div>
        <h5 className='fw-bold fs-6 mt-8'>
          Danh sách order ({data?.metadata?.total_records || 0})
        </h5>
        <div className='card card-flush border-gray-300 mt-3 sapp-overflow-y-layout'>
          <div className='container'>
            <div className='row px-4 py-8 sapp-overflow-y-layout'>
              <SappTable
                headers={headers}
                loading={isLoading}
                data={data?.orders}
                isCheckedAll={false}
                hasCheck={false}
                onChange={() => {}}
              >
                {isLoading ? (
                  <>
                    {headers.map((header) => (
                      <LoadingTable key={header.label} headers={headers} />
                    ))}
                  </>
                ) : (
                  <>
                    {data?.orders.map((item: IOrder, index: number) => {
                      let productNames = ''
                      if (item.products.length > 1) {
                        productNames = `Chuỗi khóa học "${item.products
                          .map((item) => item.name)
                          .join('; ')}"`
                      } else {
                        productNames = `${item.products.map((item) => item.name)}`
                      }

                      return (
                        <tr key={index}>
                          <td className='px-2 text-center'>
                            {index +
                              1 +
                              (data?.metadata?.page_index - 1) * data?.metadata?.page_size}
                          </td>
                          <td className='text-start'>{productNames}</td>
                          <td className='text-start'>{item.full_name}</td>
                          <td className='text-start'>{item.deal_id}</td>
                          <td className='text-start'>{formatPrice(item.original_price)}</td>
                          <td className='text-start'>{formatPrice(item.final_price)}</td>
                          <td className='text-start'>
                            <span className='text-start'>
                              {TITLES_ORDER_STATUS[item.status] ?? ''}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                )}
              </SappTable>
              <PagiantionSAPP
                currentPage={data?.metadata?.page_index || 1}
                pageSize={data?.metadata?.page_size || 10}
                totalItems={data?.metadata?.total_records}
                handlePaginationChange={handlePaginationChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderDetail
