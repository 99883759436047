import HeaderTab from 'src/components/base/HeaderTab'
import { PageLink } from 'src/constants'
import { LANG_PROMOTION_CODE } from 'src/constants/lang'
import { DISCOUNT_TYPE } from 'src/constants/sales'
import { IPromotionCode } from 'src/type/sales/promotionCodes'
import { formatDate } from 'src/utils'
import OverviewDetailItem from './OverviewDetailItem'

interface IProps {
  promotionCode: IPromotionCode | null
}

const OverviewDetail = ({ promotionCode }: IProps) => {
  let productNames = ''
  if ((promotionCode?.products?.length ?? 0) > 1) {
    productNames = `Chuỗi khóa học "${promotionCode?.products
      ?.map((item) => item.name)
      .join('; ')}"`
  } else {
    productNames = `${promotionCode?.products?.[0]?.name || ''}`
  }

  let promotionCodeNames = ''
  if ((promotionCode?.combinableWith?.length ?? 0) > 1) {
    promotionCodeNames = `Chuỗi khuyến mãi "${promotionCode?.combinableWith
      ?.map((item) => item.code)
      ?.join('; ')}"`
  } else {
    promotionCodeNames = `${promotionCode?.combinableWith?.[0]?.code || ''}`
  }

  const discountType = promotionCode?.discount_type === DISCOUNT_TYPE.FIXED ? 'đ' : '%'

  return (
    <div className='card mb-5 mb-xl-10'>
      <HeaderTab
        title='Overview'
        titleButton='Edit Promotion Code'
        link={`${PageLink.OPERATIONS_SALES_PROMOTION_CODES}/${promotionCode?.id}/edit`}
      />

      <div className='card-body p-9'>
        <OverviewDetailItem title={LANG_PROMOTION_CODE.code} body={promotionCode?.code} />
        <OverviewDetailItem title={LANG_PROMOTION_CODE.quantity} body={promotionCode?.quantity} />
        <OverviewDetailItem
          title={LANG_PROMOTION_CODE.start}
          body={formatDate(promotionCode?.start_date, 'dd/MM/yyyy')}
        />
        <OverviewDetailItem
          title={LANG_PROMOTION_CODE.end}
          body={formatDate(promotionCode?.end_date, 'dd/MM/yyyy')}
        />
        <OverviewDetailItem title={LANG_PROMOTION_CODE.applyForProduct} body={productNames} />
        <OverviewDetailItem
          title={LANG_PROMOTION_CODE.discount}
          body={`${promotionCode?.discount_value} ${discountType}`}
        />
        <OverviewDetailItem title={LANG_PROMOTION_CODE.combinedWith} body={promotionCodeNames} />
      </div>
    </div>
  )
}

export default OverviewDetail
