import { useEffect } from 'react'

type Callback = () => void

const useKeyboardShortcut = (key: string, callback: Callback) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === key) {
        event.preventDefault()
        callback()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [key, callback])
}

export default useKeyboardShortcut
