import React from 'react'
import './search.scss'

interface IProps {
  placeholder?: string
  value?: string | number | readonly string[] | undefined
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  showSearch?: boolean
  bgSearch?: boolean
  className?: string
  smallSearch?: boolean
  isListScreen?: boolean
  onSubmit?: any
  searchRef?: React.LegacyRef<HTMLInputElement> | undefined
}

const Search = ({
  placeholder,
  value,
  onChange,
  showSearch,
  bgSearch = true,
  className,
  smallSearch = false,
  isListScreen = false,
  onSubmit,
  searchRef,
}: IProps) => {
  return (
    <div
      className={`${showSearch ? '' : 'd-none'} ${
        bgSearch ? '' : 'sapp-search-header'
      } d-block position-relative mb-lg-0 ${smallSearch ? 'w-200px' : 'w-100'}`}
    >
      <input type='hidden' autoComplete='off' />

      <i
        className={`ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ${
          smallSearch ? 'ms-3 fw-bold' : 'ms-5'
        }`}
      ></i>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit && onSubmit()
        }}
      >
        <input
          type='text'
          className={`search-input form-control sapp-form-control-custom ${className} ${
            smallSearch
              ? 'sapp-search-case-study ps-16 fs-base bg-gray-100 fw-bold border-0'
              : 'ps-13'
          } ${isListScreen ? 'sapp-h-40' : 'sapp-h-45px'}`}
          name='search'
          placeholder={placeholder ?? 'Search Question'}
          value={value}
          onChange={onChange}
          style={{ border: bgSearch ? '' : 'none' }}
          autoComplete='off'
          ref={searchRef}
        />
      </form>

      <span
        className='search-spinner position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5'
        data-kt-search-element='spinner'
      >
        <span className='spinner-border h-15px w-15px align-middle text-gray-400'></span>
      </span>

      <span
        className='search-reset btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4'
        data-kt-search-element='clear'
      >
        <i className='ki-outline ki-cross fs-2 fs-lg-1 me-0'></i>
      </span>
    </div>
  )
}

export default Search
