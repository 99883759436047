import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { CoursesAPI } from 'src/apis/courses'
import { SaleProductAPI } from 'src/apis/sales/products'
import SappDrawer from 'src/components/base/SappDrawer'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { VALIDATE_CODE_MIN, VALIDATION_FIELD } from 'src/constants'
import { OPTIONS_CONSTRUCTION_MODE, OPTIONS_PRODUCT_STATUS } from 'src/constants/sales'
import useShopFilter from 'src/hooks/use-shop-filter'
import { ISaleProduct } from 'src/type/sales/products'
import { formatPrice, parsePrice } from 'src/utils'
import { z } from 'zod'

const { Option } = Select

interface IProps {
  type: 'create' | 'update'
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  fetchData: (pageIndex: number, pageSize: number, params?: Object) => void
  dataUpdate?: ISaleProduct | null
}
const SaleProductSideModal = ({ type, isOpen, setIsOpen, fetchData, dataUpdate }: IProps) => {
  const validationSchema = z.object({
    'side-product-name': z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1, VALIDATE_CODE_MIN),
    'side-category': z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .refine((value) => value !== '', VALIDATION_FIELD),
    'side-construction-mode': z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .refine((value) => value !== '', VALIDATION_FIELD),
    'side-price': z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(0),
    'side-status': z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .refine((value) => value !== '', VALIDATION_FIELD),
    'side-product-code': z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1, VALIDATE_CODE_MIN),
  })

  const { control, handleSubmit, reset, setValue, getValues } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const {
    dataList: categoryList,
    getData: getCategory,
    setDataList: setCategoryList,
    debounceGetData: debounceGetCategory,
    handleNextPage,
  } = useShopFilter({
    callback: CoursesAPI.getCategory,
    type: 'category',
    key: 'course_categories',
  })

  useEffect(() => {
    if (type === 'update' && dataUpdate) {
      setCategoryList((prev: any) => ({
        ...prev,
        data: [
          {
            id: dataUpdate?.course_category?.id,
            name: dataUpdate?.course_category?.name,
          },
        ],
      }))
      setValue('side-product-name', dataUpdate?.name)
      setValue('side-category', dataUpdate?.course_category?.id)
      setValue('side-construction-mode', dataUpdate?.construction_mode)
      setValue('side-price', formatPrice(dataUpdate?.price))
      setValue('side-product-code', dataUpdate?.code)
      setValue('side-status', dataUpdate?.status)
    }
  }, [dataUpdate])

  const handleClose = () => {
    reset()
    setIsOpen(false)
  }

  const onSubmit = async () => {
    const data = {
      name: getValues('side-product-name'),
      category_id: getValues('side-category'),
      construction_mode: getValues('side-construction-mode'),
      price: parsePrice(getValues('side-price')),
      code: getValues('side-product-code'),
      status: getValues('side-status'),
    }

    try {
      if (type === 'create') {
        const res = await SaleProductAPI.createSaleProduct(data)
        if (res?.success) {
          toast.success('Create product successfully')
          handleClose()
          fetchData(1, 10)
        } else {
          toast.error('Create product failed')
        }
      } else {
        if (!dataUpdate) return
        console.log(data)
        const res = await SaleProductAPI.updateSaleProduct(dataUpdate?.id, data)
        if (res?.success) {
          toast.success('Update product successfully')
          handleClose()
          fetchData(1, 10)
        } else {
          toast.error('Update product failed')
        }
      }
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const handleChangePrice = () => {
    const price = parsePrice(getValues('side-price'))
    setValue('side-price', formatPrice(price))
  }

  return (
    <SappDrawer
      open={isOpen}
      title={`${type === 'create' ? 'Create new product' : 'Edit product'}`}
      handleSubmit={handleSubmit(onSubmit)}
      width={'50%'}
      handleClose={handleClose}
      confirmOnclose
      cancelButtonCaption={'Cancel'}
      okButtonCaption={'Save'}
      footerClassName='footer-side-modal'
      classNameCancel='min-w-100px me-5 text-gray-800'
    >
      <div className='sapp-mb-24px'>
        <HookFormTextField
          name='side-product-name'
          control={control}
          placeholder=''
          label='Product Name'
          required
        />
      </div>
      <div className='sapp-mb-24px'>
        <HookFormTextField
          name='side-product-code'
          control={control}
          placeholder=''
          label="Product's code"
          required
        />
      </div>
      <div className='sapp-mb-24px'>
        <HookFormSelectAntd
          control={control}
          name='side-category'
          placeholder='None'
          label='Category'
          size='large'
          showSearch
          handleNextPage={handleNextPage}
          classNameHeight='sapp-h-45px'
          onSearch={async (e) => {
            await debounceGetCategory(e)
          }}
          onFocus={async () => {
            if (categoryList.data?.length === 0) {
              await getCategory()
            }
          }}
          onChange={async (e: any) => {
            if (e === undefined) {
              await debounceGetCategory()
            }
          }}
          loading={categoryList.loading}
          allowClear
          isListScreen
          required
        >
          {categoryList.data?.map((e) => (
            <Option key={e.id} value={e.id}>
              {e.name}
            </Option>
          ))}
        </HookFormSelectAntd>
      </div>

      <div className='sapp-mb-24px'>
        <HookFormSelectAntd
          control={control}
          name='side-construction-mode'
          placeholder='None'
          label='Construction Mode'
          required
          showSearch
          classNameHeight='sapp-h-45px'
        >
          {OPTIONS_CONSTRUCTION_MODE?.map((item: any, index: number) => (
            <Option key={index} value={item.value}>
              {item.label}
            </Option>
          ))}
        </HookFormSelectAntd>
      </div>
      <div className='sapp-mb-24px'>
        <HookFormTextField
          type='text'
          name='side-price'
          control={control}
          placeholder=''
          label='Price'
          postFix={<div className='ps-3 pe-3 text-gray-700'>đ</div>}
          onChange={handleChangePrice}
          required
        />
      </div>
      <div className='sapp-mb-24px'>
        <HookFormSelectAntd
          control={control}
          name='side-status'
          placeholder='None'
          label='Status'
          required
          showSearch
          classNameHeight='sapp-h-45px'
        >
          {OPTIONS_PRODUCT_STATUS?.map((item: any, index: number) => (
            <Option key={index} value={item.value}>
              {item.label}
            </Option>
          ))}
        </HookFormSelectAntd>
      </div>
    </SappDrawer>
  )
}
export default memo(SaleProductSideModal)
