import { useInfiniteQuery } from 'react-query' // Import useInfiniteQuery
import { getAPIStaffs } from 'src/apis/staffs'

const useStaffs = (params: Object) => {
  const fetchStaffs = async (page_index: number, page_size: number, params: Object) => {
    const res = await getAPIStaffs(page_index, page_size, params)
    return res
  }

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } =
    useInfiniteQuery({
      queryKey: ['staffs', params], // Query key
      queryFn: ({ pageParam = 1 }) => {
        return fetchStaffs(pageParam, 10, params) // Fetch with pageParam and a fixed page size
      },
      getNextPageParam: (lastPage: any) => {
        return lastPage?.data.metadata.page_index < lastPage?.data.metadata.total_pages
          ? lastPage?.data.metadata.page_index + 1
          : undefined
      },
      refetchOnWindowFocus: false,
    })

  return {
    staffs: data?.pages.flatMap((page) => page.data.staffs) ?? [], // Flatten subjects from all pages
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    refetch,
  }
}

export default useStaffs
