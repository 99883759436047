import MenuItem from './MenuItem'

interface IProps {
  menuLMS?: Array<any> | undefined
  menuAdmin?: Array<any> | undefined
  activeAdmin?: boolean
  menuShop?: Array<any> | undefined
  menuUser?: Array<any> | undefined
  isActiveUser?: boolean
  isActiveShop: boolean
  menuFAQs?: Array<any> | undefined
  isActiveFAQs?: boolean
  menuMarketing?: Array<any> | undefined
  activeMarketing?: boolean
  menuOperations?: Array<any> | undefined
  isActiveOperations?: boolean
}

const MenuRight = ({
  menuLMS,
  activeAdmin,
  menuAdmin,
  menuShop,
  menuUser,
  isActiveShop,
  isActiveUser,
  menuFAQs,
  isActiveFAQs,
  menuMarketing,
  activeMarketing,
  menuOperations,
  isActiveOperations,
}: IProps) => {
  const menuData = [
    {
      title: 'Admin',
      menu: menuAdmin,
      active: activeAdmin,
    },
    {
      title: 'Shop',
      menu: menuShop,
      active: isActiveShop,
    },
    {
      title: 'User',
      menu: menuUser,
      active: isActiveUser,
    },
    {
      title: 'FAQs',
      menu: menuFAQs,
      active: isActiveFAQs,
    },
    {
      title: 'Marketing',
      menu: menuMarketing,
      active: activeMarketing,
    },
    {
      title: 'Operations',
      menu: menuOperations,
      active: isActiveOperations,
    },
  ]

  const activeMenu = menuData.find((item) => item.active) || {
    title: 'LMS',
    menu: menuLMS,
  }

  return (
    <div className='app-sidebar-secondary overflow-y-auto'>
      <div className='menu menu-sub-indention menu-rounded menu-column menu-active-bg-primary menu-title-gray-700 menu-icon-gray-700 menu-state-white menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6'>
        <div className='px-4 px-lg-6'>
          <div className='menu-item'>
            <div className='menu-content'>
              <span className='menu-section fs-5 fw-bolder ps-1 py-1'>{activeMenu.title}</span>
            </div>
          </div>
          {activeMenu.menu?.map((item) => (
            <MenuItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MenuRight
