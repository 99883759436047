import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import {
  ICreatePromotionCode,
  IGetPromotionCode,
  IGetPromotionCodes,
  IPromotionCode,
  IPromotionCodes,
} from 'src/type/sales/promotionCodes'

export class PromotionCodeAPI {
  static async getPromotionCodes({
    page_index,
    page_size,
    params,
    otherQuery,
  }: IGetPromotionCodes): Promise<IResponse<IPromotionCodes>> {
    return fetcher(
      `/product-promotions?page_index=${page_index}&page_size=${page_size}${
        otherQuery ? `&${otherQuery}` : ''
      }`,
      {
        params,
      }
    )
  }

  static async getPromotionCode({ id }: IGetPromotionCode): Promise<IResponse<IPromotionCode>> {
    return fetcher(`/product-promotions/${id}`)
  }

  static async createPromotionCode(data: ICreatePromotionCode): Promise<IResponse<any>> {
    return fetcher(`/product-promotions`, {
      method: 'POST',
      data,
    })
  }

  static async updatePromotionCode(id: string, data: any): Promise<IResponse<any>> {
    return fetcher(`/product-promotions/${id}`, {
      method: 'PUT',
      data,
    })
  }

  static async deletePromotionCode(id: string): Promise<IResponse<any>> {
    return fetcher(`/product-promotions/${id}`, {
      method: 'DELETE',
    })
  }
}
