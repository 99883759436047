import { differenceInMinutes, differenceInSeconds, format } from 'date-fns'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { AppConfig } from 'src/constants'
import 'src/global.d.ts'
import { IParticipantInTest } from 'src/type/entrancetest'

export const getActToken = (): string => {
  return localStorage.getItem('actToken') || ''
}

export const getRefreshToken = (): string => {
  return localStorage.getItem('refreshToken') || ''
}

export const setActToken = (accToken: string) => {
  localStorage.setItem('actToken', accToken)
}

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refreshToken', refreshToken)
}

export const removeJwtToken = () => {
  localStorage.removeItem('actToken')
  localStorage.removeItem('refreshToken')
  localStorage.clear()
}

export function getFileSizeMB(size: number, fractionDigits?: number | undefined): number {
  if (size <= 0) return 0
  if (fractionDigits === undefined || fractionDigits < 0) fractionDigits = 0
  return Number(parseFloat(`${size / (1024 * 1024)}`).toFixed(fractionDigits))
}
/**
 * @description Hiện thị bytes sang dạng khác gọn hơn
 *
 * @param {number} bytes
 * @return {*}
 */
export const displayBytes = (bytes?: number | null) => {
  if (!bytes) {
    return '-'
  }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 KB'
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const result = (bytes / Math.pow(1024, i)).toFixed(2)
  return result + ' ' + sizes[i]
}

export function checkTypeFile(type: string) {
  return AppConfig.imageFileTypeWhiteList.includes(type)
}

export const isValidHttpUrl = (string: string | URL) => {
  let url: URL
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const validatePassword = /^(?=.*[A-Z])(?=.*\d).{8,}$/

export const phoneRegExp = /^(0\d{9}|0\d{10})$/

export const getUppercaseByNumber = (num: number): string => {
  let result = ''
  while (num > 0) {
    let remainder = num % 26
    if (remainder === 0) {
      remainder = 26
      num--
    }
    let char = String.fromCharCode(remainder + 64)
    result = char + result
    num = Math.floor(num / 26)
  }
  return result
}

export const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
export const fileExtension = '.xlsx'

export const usernamePattern = /^[A-Za-z0-9](?:[A-Za-z0-9_.-]*[A-Za-z0-9])?$/

export function secondsToMinutesAndSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  // Format the result as "minutes:seconds"
  const formattedTime = `${minutes > 9 ? '' : '0'}${minutes}:${
    remainingSeconds < 10 ? '0' : ''
  }${remainingSeconds}`

  return formattedTime
}

export const formatDate = (
  date: Date | null | undefined | string,
  formatType: string = 'yyyy-MM-dd'
) => {
  return date ? format(new Date(date), formatType) : null
}

export const htmlToRaw = (html: string): string => {
  if (!html) {
    return ''
  }
  let result = ''
  let inTag = false
  for (let i = 0; i < html.length; i++) {
    let char = html[i]
    if (char === '<') {
      inTag = true
    } else if (char === '>') {
      inTag = false
    } else if (!inTag) {
      result += char
    }
  }
  result = result.replace(/&/g, '&')
  result = result.replace(/</g, '<')
  result = result.replace(/>/g, '>')
  result = result.replace(/"/g, '"')
  result = result.replace(/'/g, "'")
  return decodeHtml(result)?.trim()
}

const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const cleanParamsAPI = (params: Object) => {
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value !== null && value !== '')
  )
}

export const removeDuplicateById = <T extends { dom_id: any }>(array: T[], id: keyof T): T[] => {
  const seen = new Set()
  return array.filter((item) => {
    if (seen.has(item[id])) {
      return false
    } else {
      seen.add(item[id])
      return true
    }
  })
}

export const sizeInBytes = (megabytes: number) => {
  return megabytes * 1024 * 1024
}

export const getCourseFullLevel = () => {
  return window.localStorage.getItem('fullLevel') === 'true' ? true : false
}

export const setCourseLevel = (level: string) => {
  localStorage.setItem('fullLevel', level)
}

export const formatISOFromDate = (year: number, month: number, day: number) => {
  return new Date(year, month, day, 0, 0, 0)
}

export const formatISOToDate = (year: number, month: number, day: number) => {
  return new Date(year, month, day, 23, 59, 59, 999)
}

export function getDateInfo(inputDate: Date) {
  const date = new Date(inputDate)

  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return {
    day,
    month,
    year,
  }
}

export const bytesToGB = (bytes: number) => {
  const gigabytes = bytes / (1024 * 1024 * 1024)
  return gigabytes.toFixed(2) // Round to 2 decimal places
}

export const convertItemSelect = (items: Array<any> | undefined) => {
  return items && items?.map((item: any) => ({ label: item?.name, value: item?.id }))
}

export const convertDateStringDayMonthYear = (
  dateString: string,
  type: 'string' | 'iso' = 'string'
) => {
  // Tạo đối tượng Date từ chuỗi ngày tháng
  const dateObject = new Date(dateString)

  // Lấy ngày, tháng, năm từ đối tượng Date
  const year = dateObject.getFullYear()
  const month = `0${dateObject.getMonth() + 1}`.slice(-2) // Tháng bắt đầu từ 0
  const day = `0${dateObject.getDate()}`.slice(-2)

  // Tạo chuỗi định dạng mới
  const date = `${year}-${month}-${day}`
  const isoDateString = dayjs(dateString).utc().format()
  return type === 'iso' ? isoDateString : date
}

export const convertSnakeCaseToTitleCase = (snakeCaseString: string) => {
  return snakeCaseString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const convertMathToImage = async (element: any) => {
  const viewer = com?.wiris?.js?.JsPluginViewer
  if (viewer && element) {
    try {
      await viewer.parseElement(element, true, function () {})
    } catch (error) {
      console.error('Error:', error)
    }
  }
}

export function cleanObject(obj: any) {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === 'undefined' ||
      obj[propName] === 'null'
    ) {
      delete obj[propName]
    } else if (typeof obj[propName] === 'object') {
      cleanObject(obj[propName])
      if (Object.keys(obj[propName]).length === 0) {
        delete obj[propName]
      }
    }
  }
  return obj
}

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const useGetDataQuery = (
  queryKey: string,
  params: Object,
  fetchFunction: () => Promise<any>,
  enabled?: boolean,
  onError?: ((err: unknown) => void) | undefined
) => {
  const fetchData = async () => {
    const { data } = await fetchFunction()
    return data
  }

  return useQuery([queryKey, params], fetchData, {
    enabled: enabled,
    onError: onError,
    retry: false,
  })
}

export const beforeUpload = (file: File) => {
  const isExcel =
    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'application/vnd.ms-excel'
  const isCSV = file.type === 'text/csv'
  if (!isExcel && !isCSV) {
    toast.error('You can only upload Excel (.xlsx) or CSV (.csv) files!')
    return false
  }
  if (file.size > 5 * 1024 * 1024) {
    toast.error('The file is too large! It must be less than or equal to 5 MB!')
    return false
  }
  return true
}

export const handleParticipantLevel = (data: IParticipantInTest, name?: string) => {
  switch (name) {
    case 'ACCA':
      return data?.detail?.acca_level ?? '--'
    case 'CFA':
      return data?.detail?.cfa ?? '--'
    case 'CMA':
      return data?.detail?.cma_level ?? '--'
    default:
      return '--'
  }
}

export const formatTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  const formattedHours = hours > 0 ? `${hours}h` : ''
  const formattedMinutes = remainingMinutes > 0 ? `${remainingMinutes}m` : ''

  return formattedHours + formattedMinutes || '--'
}

export const convertString = (input: string | undefined) => {
  // Tách các từ bằng dấu gạch dưới, sau đó viết hoa chữ cái đầu mỗi từ
  return (
    input
      ?.toLowerCase() // Chuyển tất cả về chữ thường
      .split('_') // Tách chuỗi dựa vào dấu gạch dưới
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Viết hoa chữ cái đầu mỗi từ
      .join(' ') || '--'
  ) // Ghép lại các từ bằng dấu cách
}

export const convertTime = (start: any, end: any) => {
  const startTime = new Date(start) as any
  const endTime = new Date(end) as any

  // Tính số giây chênh lệch
  const diffInSeconds = Math.abs(differenceInSeconds(endTime, startTime))

  // Nếu thời gian chênh lệch nhỏ hơn 1 phút, hiển thị giây
  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`
  }

  // Nếu thời gian lớn hơn 1 phút, tính số phút
  const diffInMinutes = Math.abs(differenceInMinutes(endTime, startTime))
  const remainingSeconds = diffInSeconds % 60

  if (diffInMinutes === 0) {
    return `${remainingSeconds}s` // Nếu chỉ có vài giây, không hiển thị phút
  }

  return diffInMinutes > 0 && remainingSeconds === 0
    ? `${diffInMinutes}m`
    : `${diffInMinutes}m ${remainingSeconds}s` // Hiển thị cả phút và giây nếu cần
}

export const SIZES = {
  small: 'small',
  medium: 'medium',
  lager: 'lager',
}

export const convertQuizType = (quizType: string) => {
  // Convert the enum value to a readable string
  return quizType
    .split('_') // Split the string by underscores
    .map(
      (
        word,
        index // Capitalize the first letter of each word
      ) =>
        index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ')
}

/**
 * Chuyển đổi chuỗi văn bản thành slug để sử dụng trong URL
 * @param {string} text - Chuỗi văn bản đầu vào cần chuyển đổi
 * @returns {string} - Chuỗi văn bản đã được chuyển đổi thành slug
 */
export const generateSlug = (text: string) => {
  return text
    ?.toLowerCase() // Chuyển toàn bộ chuỗi sang chữ thường
    ?.normalize('NFD') // Chuẩn hóa chuỗi, tách các ký tự tổ hợp (dấu và chữ cái)
    ?.replace(/[\u0300-\u036F]/g, '') // Loại bỏ các dấu kết hợp (dấu sắc, huyền, hỏi, ngã...)
    ?.replace(/đ/g, 'd') // Thay thế ký tự đặc biệt 'đ'
    ?.replace(/Đ/g, 'd') // Thay thế ký tự đặc biệt 'Đ'
    ?.replace(/[^a-z0-9]+/g, '-') // Loại bỏ các ký tự không phải chữ cái, số và thay bằng dấu gạch ngang
    ?.replace(/^-+|-+$/g, '') // Loại bỏ các dấu gạch ngang ở đầu và cuối chuỗi
    ?.replace(/-+/g, '-') // Thay thế nhiều dấu gạch ngang liên tiếp bằng một dấu gạch ngang duy nhất
}

export const onLinkSocial = (link: string) => {
  window.open(link, '_blank')
}

export const mappingAddress = (data: Array<string | null | undefined>) => {
  if (!data.length) return ''
  const clearArr = data.filter((el) => el)
  return clearArr?.join(', ')
}

/**
 * @param {number | string | null | undefined} text Giá trị cần định dạng.
 * @param {string} [formatType=' '] Ký tự dùng làm dấu phân cách hàng nghìn. Mặc định là khoảng trắng.
 *
 * @example
 * formatPrice(1234567.89);      // Output: "1 234 567.89" (với formatType = ' ')
 * formatPrice(1234567.89, ',');  // Output: "1,234,567.89" (với formatType = ',')
 * formatPrice("1234567.89");    // Output: "1 234 567.89" (với formatType = ' ')
 * formatPrice(null);             // Output: ""
 * formatPrice(undefined);       // Output: ""
 * formatPrice("abc");           // Output: ""
 */
export const formatPrice = (text: number | string | null | undefined, formatType = ' '): string => {
  if (text === null || text === undefined) return ''

  const number = Number(text)

  if (isNaN(number)) return ''

  const parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, formatType)
  return parts.join('.')
}

/**
 * @param {string | null | undefined} text Chuỗi giá đã được định dạng.
 * @param {string} [formatType] Loại định dạng của chuỗi giá. Có thể là khoảng trắng hoặc dot. Mặc định là khoảng trắng
 *
 * @example
 * parsePrice("1,000,000.00", ","); // Output: 1000000
 * parsePrice("1 000 000.00", " "); // Output: 1000000
 * parsePrice("1.000.000", "."); // Output: 1000000
 * parsePrice(null); // Output: 0
 * parsePrice(undefined); // Output: 0
 * parsePrice(""); // Output: 0
 */
export const parsePrice = (text: string | null | undefined, formatType = ' '): number => {
  if (text === null || text === undefined || text?.trim() === '') {
    return 0
  }

  switch (formatType) {
    case ' ':
      text = text.replace(/\s/g, '')
      break
    case '.':
      text = text.replace(/\./g, '')
  }

  const parts = text.split('.')
  let priceString = parts[0]
  if (parts.length > 1) {
    priceString += '.' + parts.slice(1).join('')
  }

  const price = parseFloat(priceString)

  return isNaN(price) ? 0 : price
}
