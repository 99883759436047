import { AutoComplete, Skeleton } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import _debounce from 'lodash/debounce'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { KTIcon } from 'src/_metronic/helpers'
import { NoDataIcon } from 'src/common/CustomIcons'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import SappLabel from 'src/components/base/label/SappLabel'
import { normalizeLikeParam } from 'src/utils/string'
import './HookFormTag.scss'

interface ITag {
  id?: string
  name?: string
  description?: string
  used_in_entrance_test?: boolean
}

type FilterFunc<OptionType> = (inputValue: string, option?: OptionType) => boolean

interface IHookFormTagProps {
  name: string
  control: Control<any>
  defaultValue?: ITag[]
  label?: string
  dropdownStyle?: React.CSSProperties | undefined
  fetch: (params: any) => Promise<any>
  filterOption?: boolean | FilterFunc<DefaultOptionType> | undefined
  placeholder?: string
  skeleton?: boolean
  required?: boolean
  className?: string
  guideline?: string[]
  disabled?: boolean
  classCustomTag?: string
  isCanAddNew?: boolean
  dataName?: string
  isEditTopic?: boolean
  listQuestionCategory?: any
}

/**
 * Component HookFormTagFetch
 * @component
 * @param {IHookFormTagProps} props - Props của component
 * @returns {JSX.Element}
 */
const HookFormTag = ({
  name,
  control,
  defaultValue,
  dropdownStyle,
  fetch,
  filterOption,
  placeholder,
  skeleton,
  label,
  required,
  className = '',
  guideline,
  disabled,
  classCustomTag,
  isCanAddNew = true,
  dataName,
  isEditTopic = true,
  listQuestionCategory,
}: IHookFormTagProps) => {
  const [firstOption, setFirstOption] = useState<string | undefined>('')
  const [apiOptions, setApiOptions] = useState<ITag[]>([])
  const autoCompleteRef = useRef<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)

  /**
   * Hàm gọi API với debounce
   * @param {string} text - Đoạn văn bản tìm kiếm
   * @returns {void}
   */
  const debounceFetchApiOptions = useCallback(
    _debounce(async (text: string) => {
      try {
        const response = await fetch({ text, page_index: pageIndex, page_size: 10 })
        if (dataName && response?.success) {
          const data = response?.data?.[dataName] ?? []
          const newApiOptions = [...apiOptions, ...data].filter(
            (obj, index, self) => self.findIndex((o) => o.id === obj.id) === index
          )
          setApiOptions(newApiOptions)
          setPageIndex(response?.data?.meta?.page_index ?? response?.data?.metadata?.page_index)
          setTotalPages(response?.data?.meta?.total_pages ?? response?.data?.metadata?.total_pages)
        } else {
          setApiOptions(response?.data)
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }, 1000),
    [pageIndex]
  )

  const handleNextPage = () => {
    // When loading don't fetch next page
    if (loading) return

    if (pageIndex < totalPages) {
      setPageIndex(pageIndex + 1)
    }
  }

  useEffect(() => {
    setLoading(true)
    debounceFetchApiOptions(firstOption || '')
  }, [firstOption, pageIndex])

  /**
   * Hàm xử lý sự kiện khi chọn
   * @param {string} item - Mục đã chọn
   * @param {ITag[]} value - Giá trị hiện tại
   * @param {(...event: any[]) => void} onChange - Hàm xử lý thay đổi
   * @returns {void}
   */
  const onSelect = (item: string, value: ITag[], onChange: (...event: any[]) => void): void => {
    const parseItem = JSON.parse(item)
    const text = parseItem.name
    setFirstOption('')
    if (!value?.some((e) => e.name === text)) {
      onChange([...(value ?? []), parseItem ?? []])
    }
  }

  /**
   * Hàm xử lý sự kiện xóa một tag
   * @param {string[]} value - Giá trị hiện tại
   * @param {number} i - Index của tag cần xóa
   * @param {(...event: any[]) => void} onChange - Hàm xử lý thay đổi
   * @returns {void}
   */
  const onRemove = (value: string[], i: number, onChange: (...event: any[]) => void) => {
    onChange(value?.filter((_: string, di: number) => di !== i) ?? [])
  }

  /**
   * Hàm xử lý sự kiện tìm kiếm
   * @param {string} text - Đoạn văn bản tìm kiếm
   * @returns {void}
   */
  const onSearch = async (text: string) => {
    setFirstOption(text)
  }

  /**
   * Hàm lọc cho AutoComplete
   * @type {FilterFunc<DefaultOptionType>}
   */
  const customFilterOption: FilterFunc<DefaultOptionType> = (input, option) => {
    const inputText = input?.toLowerCase() ?? ''
    if (!inputText) {
      return false
    }
    if (option?.value?.toString()) {
      try {
        const parseOption = JSON.parse(option.value.toString())
        return normalizeLikeParam(parseOption?.name).includes(normalizeLikeParam(inputText))
      } catch (error) {}
    }
    return false
  }

  const apiOptionsName = useMemo(() => {
    if (!apiOptions) return {}

    return Object.fromEntries(apiOptions?.map((e: ITag) => [e.name, true]))
  }, [apiOptions])

  const isInEntranceTest = (id?: string, name?: string) => {
    if (name === 'category') {
      const tag = listQuestionCategory.find((item: { id: string }) => item.id === id)
      return tag.used_in_entrance_test
    }
    return true
  }

  return (
    <div className='w-100'>
      {label && <SappLabel label={label} required={required} />}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={
          !skeleton
            ? ({ field: { onChange, value, name }, fieldState: { error } }) => {
                const newFirstOption = { name: firstOption || '' }
                const valueNames: Record<string, boolean> = Object.fromEntries(
                  value?.map((e: ITag) => [e.name, true]) || []
                )
                let options: ITag[] = []
                if (isCanAddNew && firstOption) {
                  if (!apiOptionsName[firstOption]) {
                    options = [newFirstOption, ...(apiOptions || [])]
                  } else {
                    options = apiOptions
                  }
                } else {
                  options = apiOptions
                }
                return (
                  <div>
                    <div
                      className={`sapp-h-45px ${
                        disabled ? 'sapp-hook-form-tag-disabled' : ''
                      } ${className} sapp-hook-form-tag ${error?.message ? 'is-error' : ''}`}
                      onClick={() => autoCompleteRef.current?.focus()}
                    >
                      {value?.map((t: ITag, i: number) => {
                        return (
                          <div key={i} className='sapp-tag'>
                            <span>{t.name}</span>
                            {(isEditTopic ||
                              !listQuestionCategory.some((cat: ITag) => {
                                return cat.id === t.id
                              }) ||
                              !isInEntranceTest(t.id, name)) && (
                              <div
                                className='sapp-tag-delete'
                                onClick={() => onRemove(value, i, onChange)}
                              >
                                {!disabled && (
                                  <KTIcon iconName='cross' iconType='outline' className='fs-3' />
                                )}
                              </div>
                            )}
                          </div>
                        )
                      })}
                      {!disabled && (
                        <div
                          className={`${
                            classCustomTag ?? ''
                          } position-relative sapp-tag-auto-complete`}
                        >
                          <AutoComplete
                            value={firstOption}
                            ref={autoCompleteRef}
                            className='sapp-tag-auto-complete-component fs-6'
                            defaultActiveFirstOption
                            filterOption={filterOption ?? customFilterOption}
                            onSearch={onSearch}
                            onSelect={(e) => onSelect(e, value, onChange)}
                            placeholder={(!value?.length || value.length === 0) && placeholder}
                            dropdownStyle={{
                              zIndex: '2000',
                              ...(dropdownStyle && dropdownStyle),
                              minWidth: '150px',
                            }}
                            disabled={disabled}
                            notFoundContent={
                              <div className='ant-select-item-empty m-4'>
                                <div className='ant-empty ant-empty-normal ant-empty-small text-center'>
                                  <div className='ant-empty-image'>
                                    <NoDataIcon />
                                  </div>
                                  <div
                                    className='ant-empty-description'
                                    style={{ color: 'rgba(0, 0, 0, 0.25)' }}
                                  >
                                    No data
                                  </div>
                                </div>
                              </div>
                            }
                            onPopupScroll={(e) => {
                              const { target } = e
                              if (
                                Math.ceil((target as HTMLElement).scrollTop) +
                                  Math.ceil((target as HTMLElement).offsetHeight) ===
                                Math.ceil((target as HTMLElement).scrollHeight)
                              ) {
                                handleNextPage()
                              }
                            }}
                          >
                            {options?.map((item: ITag, i: number) => {
                              return (
                                <Fragment key={i}>
                                  {item?.name && (
                                    <AutoComplete.Option
                                      value={JSON.stringify(item)}
                                      disabled={valueNames[item.name]}
                                    >
                                      {item.name}
                                    </AutoComplete.Option>
                                  )}
                                </Fragment>
                              )
                            })}
                          </AutoComplete>
                          {loading ? (
                            <div className='sapp-arrow-icon-tag_spinner'>
                              <div className='spinner-border ' role='status'>
                                <span className='sr-only'></span>
                              </div>
                            </div>
                          ) : (
                            <svg
                              className='sapp-arrow-icon-tag'
                              xmlns='http://www.w3.org/2000/svg'
                              width='15'
                              height='9'
                              fill='none'
                              viewBox='0 0 15 9'
                            >
                              <path
                                fill='#7E8299'
                                d='M7.89 8.24a1 1 0 01-.71-.29l-6-6A1 1 0 112.59.53l5.3 5.3 5.29-5.3a1 1 0 01.71 1.42l-6 6a1 1 0 01-.7.29z'
                              ></path>
                            </svg>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <GuidelineField guideline={guideline} />
                      <ErrorMessage>{error?.message}</ErrorMessage>
                    </div>
                  </div>
                )
              }
            : () => <Skeleton.Input active size='large' className='w-100' />
        }
      />
    </div>
  )
}

export default HookFormTag
