import React from 'react'

interface IProps {
  title: string
  body?: string | undefined | number | null | React.ReactNode
}

const OverviewDetailItem = ({ title, body }: IProps) => {
  return (
    <div className='row mb-7'>
      <label className='col-lg-3 fw-semibold text-muted'>{title}</label>

      {body && (
        <div className='col-lg-9'>
          <span className='fw-semibold text-gray-800 fs-6'>{body}</span>
        </div>
      )}
    </div>
  )
}

export default OverviewDetailItem
