export const PRODUCT_STATUS = {
  ACTIVE: 'ACTIVE',
  DEACTIVE: 'DEACTIVE',
}

export const OPTIONS_PRODUCT_STATUS = [
  {
    label: 'Active',
    value: PRODUCT_STATUS.ACTIVE,
  },
  {
    label: 'Deactive',
    value: PRODUCT_STATUS.DEACTIVE,
  },
]

export const PROMOTION_CODE_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  ONGOING: 'ONGOING',
  END: 'END',
}

export const OPTIONS_PROMOTION_CODE_STATUS = [
  {
    label: 'Not started',
    value: PROMOTION_CODE_STATUS.NOT_STARTED,
  },
  {
    label: 'Ongoing',
    value: PROMOTION_CODE_STATUS.ONGOING,
  },
  {
    label: 'End',
    value: PROMOTION_CODE_STATUS.END,
  },
]

export const CONSTRUCTION_MODE = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  BLENDED: 'BLENDED',
  RECORD: 'RECORD',
}

export const OPTIONS_CONSTRUCTION_MODE = [
  {
    label: 'Online',
    value: CONSTRUCTION_MODE.ONLINE,
  },
  {
    label: 'Offline',
    value: CONSTRUCTION_MODE.OFFLINE,
  },
  {
    label: 'Blended',
    value: CONSTRUCTION_MODE.BLENDED,
  },
  {
    label: 'Record',
    value: CONSTRUCTION_MODE.RECORD,
  },
]

export const DISCOUNT_TYPE = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE',
}

export const OPTIONS_DISCOUNT_TYPE = [
  {
    label: 'Fixed',
    value: DISCOUNT_TYPE.FIXED,
  },
  {
    label: 'Percentage',
    value: DISCOUNT_TYPE.PERCENTAGE,
  },
]

export const PROMOTION_CODE_DETAIL_URL = {
  OVERVIEW: 'overview',
  SETTINGS: 'settings',
  LIST_ORDERS: 'list-orders',
}

export const ORDER_STATUS = {
  INPROGRESS: 'INPROGRESS',
  SUCCESS: 'SUCCESS',
  CANCEL: 'CANCEL',
}

export const OPTIONS_ORDER_STATUS = [
  {
    label: 'Chờ thanh toán',
    value: ORDER_STATUS.INPROGRESS,
  },
  {
    label: 'Đã thanh toán',
    value: ORDER_STATUS.SUCCESS,
  },
  {
    label: 'Đã hủy',
    value: ORDER_STATUS.CANCEL,
  },
]

export const TITLES_ORDER_STATUS = {
  [ORDER_STATUS.INPROGRESS]: 'Chờ thanh toán',
  [ORDER_STATUS.SUCCESS]: 'Đã thanh toán',
  [ORDER_STATUS.CANCEL]: 'Đã hủy',
}

export const PAYMENT_METHOD_STATUS = {
  YES: 'YES',
  NO: 'NO',
}

export const OPTIONS_PAYMENT_METHOD_STATUS = [
  {
    label: 'Yes',
    value: PAYMENT_METHOD_STATUS.YES,
  },
  {
    label: 'No',
    value: PAYMENT_METHOD_STATUS.NO,
  },
]

export const PAYMENT_METHOD = {
  DIRECT_PAYMENT: 'DIRECT_PAYMENT',
  INSTALLMENT_PAYMENT: 'INSTALLMENT_PAYMENT',
}

export const TITLES_PAYMENT_METHOD = {
  [PAYMENT_METHOD.DIRECT_PAYMENT]: 'Trả thẳng qua VNPay',
  [PAYMENT_METHOD.INSTALLMENT_PAYMENT]: 'Trả góp qua VNPay',
}
