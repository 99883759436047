import { Select } from 'antd'
import { format } from 'date-fns'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { PromotionCodeAPI } from 'src/apis/sales/promotion-codes'
import LoadingTable from 'src/common/LoadingTable'
import SappTooltip from 'src/common/SappTooltip'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { PageLink } from 'src/constants'
import { OPTIONS_PROMOTION_CODE_STATUS, PROMOTION_CODE_STATUS } from 'src/constants/sales'
import { useConfirm } from 'src/hooks/use-confirm'
import { IPromotionCode, IPromotionCodes } from 'src/type/sales/promotionCodes'
import { cleanParamsAPI } from 'src/utils'
import PromotionCodeAction from './PromotionCodeAction'

const { Option } = Select

interface IProps {
  data?: IPromotionCodes
  isLoading: boolean
  fetchPromotionCodes: (pageIndex: number, pageSize: number, params?: Object) => void
  handleChangeParams: (currenPage: number, size: number, params?: Object) => void
  getParams: () => Object
  setDeleteId: Dispatch<SetStateAction<string | null>>
  setIsOpenDeleteModal: Dispatch<SetStateAction<boolean>>
  handleFilter: () => void
}

const headers = [
  {
    label: '#',
    className: 'min-w-30px px-2 text-center',
  },
  {
    label: 'Code',
    className: 'min-w-100px pe-5 ',
  },
  {
    label: 'Total',
    className: 'min-w-100px pe-5 ',
  },
  {
    label: 'Used',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Start',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'End',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Apply for',
    className: 'max-w-200px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-100px pe-5',
  },
  {
    label: '',
    className: 'min-w-50px pe-5',
  },
]

const PromotionCodeTable = ({
  data,
  isLoading,
  fetchPromotionCodes,
  handleChangeParams,
  getParams,
  setDeleteId,
  setIsOpenDeleteModal,
  handleFilter,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()

  // Using validate for input
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })

  // Set default value input status
  useEffect(() => {
    data?.promotionCodes?.forEach((item, index) => {
      setValue(`status${index}`, item.status)
    })
  }, [data])

  const handleChangeStatus = (id: string, name: string, value: string, oldValue: string) => {
    if (watch(name)) {
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
        onClick: async () => {
          try {
            const res = await PromotionCodeAPI.updatePromotionCode(id, {
              status: value,
            })
            if (res?.success) {
              toast.success('Update status successfully!')
              handleFilter()
            } else {
              toast.error('Update status failed!')
            }
          } catch (error) {
            toast.error('Update status failed!')
          }
        },
        onClose: () => setValue(name, oldValue),
      })
    }
  }

  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    const cleanedParams = cleanParamsAPI(getParams())

    handleChangeParams(pageIndex, pageSize, cleanedParams)
    fetchPromotionCodes(pageIndex, pageSize, cleanedParams)
  }

  return (
    <div className='card-body pt-7'>
      {contextHolder}
      <SappTable headers={headers} loading={isLoading} data={data?.promotionCodes} hasCheck={false}>
        {isLoading ? (
          <LoadingTable headers={headers} />
        ) : (
          <>
            {data?.promotionCodes?.map((item: IPromotionCode, index: number) => {
              let usedClassName = 'text-start'
              if (item.used_promotion_count > 0) {
                usedClassName += ' text-decoration-underline'

                if (item.used_promotion_count >= item.quantity) {
                  usedClassName += ' text-danger'
                } else {
                  usedClassName += ' text-black'
                }
              }

              let productNames = ''
              if (item.products.length > 1) {
                productNames = `Chuỗi khóa học "${item.products
                  .map((item) => item.name)
                  .join('; ')}"`
              } else {
                productNames = `${item.products.map((item) => item.name)}`
              }

              return (
                <tr key={index}>
                  <td className='px-2 text-center'>
                    {index + 1 + (data?.metadata?.page_index - 1) * data?.metadata?.page_size}
                  </td>
                  <td className='text-start'>
                    <SappTooltip
                      title={item.code}
                      link={`${PageLink.OPERATIONS_SALES_PROMOTION_CODES}/${item.id}/overview`}
                    />
                  </td>
                  <td className='text-start'>{item.quantity}</td>
                  <td className={usedClassName}>{item.used_promotion_count}</td>
                  <td className='text-start'>{format(new Date(item.start_date), 'dd/MM/yyyy')}</td>
                  <td className='text-start'>{format(new Date(item.end_date), 'dd/MM/yyyy')}</td>
                  <td className='text-start'>
                    <SappTooltip title={productNames} />
                  </td>
                  <td className='text-start'>
                    <HookFormSelectAntd
                      size='large'
                      name={`status${index}`}
                      control={control}
                      dropdownStyle={{ maxWidth: 100 }}
                      placeholder='Status'
                      filterOption={true}
                      onChange={(selectedValue: unknown) =>
                        handleChangeStatus(
                          item.id,
                          `status${index}`,
                          selectedValue as string,
                          item.status
                        )
                      }
                      disabled={watch(`status${index}`) === PROMOTION_CODE_STATUS.END}
                      isLocked={watch(`status${index}`) === PROMOTION_CODE_STATUS.END}
                      classNameHeight='sapp-h-35px'
                    >
                      {OPTIONS_PROMOTION_CODE_STATUS.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </td>
                  <td className='text-center'>
                    <PromotionCodeAction
                      key={index}
                      handleEdit={() => {
                        navigate(`${PageLink.OPERATIONS_SALES_PROMOTION_CODES}/${item.id}/edit`)
                      }}
                      handleDelete={() => {
                        setDeleteId(item.id)
                        setIsOpenDeleteModal(true)
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={data?.metadata?.page_index || 1}
        pageSize={data?.metadata?.page_size || 10}
        totalItems={data?.metadata?.total_records || 0}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}
export default PromotionCodeTable
