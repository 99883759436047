import dayjs from 'dayjs'
import { ExamAPI } from 'src/apis/exams'
import { useExam } from 'src/context/ExamContext'
import { IExamPost, IExamPut, IResponse, EXAM_PROGRAM } from 'src/type'
import ExamForm from '../ExamForm/ExamForm'

const ExamSetting = () => {
  const { data, program } = useExam()

  const editExam = async (payload: IExamPost | IExamPut): Promise<IResponse<any>> => {
    if (data?.id) {
      // Check if payload is IExamPut
      return ExamAPI.edit({
        id: data.id,
        data: payload,
      })
    }
    // Handle IExamPost case here if needed
    throw new Error('Id is required')
  }
  const getDate = (date: string | undefined) => (date ? dayjs(date).toDate() : null)
  const parsedData = (program: EXAM_PROGRAM | undefined) => {
    switch (program) {
      case EXAM_PROGRAM.CFA:
        return {
          name: dayjs(data?.name, 'MM/YYYY').toDate(),
          registration_opening_date: getDate(data?.registration_opening_date),
          early_registration_deadline: getDate(data?.early_registration_deadline),
          standard_registration_deadline: getDate(data?.standard_registration_deadline),
          registration_closing_date: getDate(data?.registration_closing_date),
          exam_subjects: data?.examination_subjects.map((item) => {
            return {
              subject_id: item?.subject?.id,
              exam_date: {
                fromDate: item?.start_date,
                toDate: item?.end_date,
              },
              allow_delete: item.allow_delete,
            }
          }),
          allow_update_name: data?.allow_update_name,
        }
      case EXAM_PROGRAM.CMA:
        return {
          registration_closing_date: getDate(data?.registration_closing_date),
          exam_subjects: data?.examination_subjects.map((item) => {
            return {
              exam_date: {
                fromDate: item?.start_date,
                toDate: item?.end_date,
              },
              allow_delete: item.allow_delete,
            }
          }),
          allow_update_name: data?.allow_update_name,
        }
      case EXAM_PROGRAM.ACCA:
        return {
          name: dayjs(data?.name, 'MM/YYYY').toDate(),
          exam_subjects: data?.examination_subjects.map((item) => {
            return {
              subject_id: item?.subject?.id,
              end_date: getDate(item?.end_date),
              allow_delete: item.allow_delete,
              subject: {
                label: item?.subject?.name,
                value: item?.subject?.id,
              },
            }
          }),
          allow_update_name: data?.allow_update_name,
        }
      default:
        break
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0 '>Edit Exam</h3>
        </div>
      </div>
      <ExamForm submitAction={editExam} defaultValues={parsedData(program)} />
    </div>
  )
}

export default ExamSetting
