import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import AreaCard from 'src/components/classroom/area/detail/AreaCard'
import RenderAreaSection from 'src/components/classroom/area/detail/RenderAreaSection'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink } from 'src/constants'
import { AREA_PROFILE, AREA_PROFILE_URL, TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import 'src/pages/classes/detail/ClassDetail.scss'
import { ITabs } from 'src/type'

const AreaDetail = () => {
  const { id } = useParams()

  const breadcrumbs: ITabs[] = [
    {
      link: PageLink.DASHBOARD,
      title: 'LMS',
    },
    {
      link: PageLink.CLASSROOM_AREA,
      title: `${TITLE_OPTIONS_AREA.facilityList}`,
    },
    {
      link: '',
      title: TITLE_OPTIONS_AREA.facilityDetail,
    },
  ]

  const tabs: ITabs[] = [
    {
      link: `${AREA_PROFILE}/${id}/${AREA_PROFILE_URL.OVERVIEW}`,
      title: 'Overview',
    },
    {
      link: `${AREA_PROFILE}/${id}/${AREA_PROFILE_URL.SETTING}`,
      title: 'Settings',
    },
    {
      link: `${AREA_PROFILE}/${id}/${AREA_PROFILE_URL.CLASSROOMLIST}`,
      title: 'Classroom List',
    },
  ]

  const {
    data: areaDetail,
    isLoading,
    refetch,
  } = useQuery(
    ['class-area-detail', id],
    async () => {
      if (!id) return
      const res = await ClassroomApi.getAreaClassDetail(id)
      return res?.data
    },
    {
      retry: false,
    }
  )

  return (
    <PageLayouts
      pageTitle='Facility Detail'
      breadcrumbs={breadcrumbs}
      classNameLayout='sapp-overflow-y-layout'
    >
      <AreaCard areaDetail={areaDetail} tabs={tabs} loading={isLoading} />
      <RenderAreaSection areaDetail={areaDetail} loading={isLoading} refetch={() => refetch()} />
    </PageLayouts>
  )
}

export default withAuthComponents(AreaDetail)
