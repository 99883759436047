import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { ICreateOrder, IGetOrders, IOrder, IOrders } from 'src/type/sales/orders'

export class OrderAPI {
  static async getOrders({
    page_index,
    page_size,
    params,
  }: IGetOrders): Promise<IResponse<IOrders>> {
    return fetcher(`/product-orders?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static async getOrder(id: string): Promise<IResponse<IOrder>> {
    return fetcher(`/product-orders/${id}`)
  }

  static async getPaymentUrl(id: string): Promise<IResponse<any>> {
    return fetcher(`/product-orders/payment-url/${id}`)
  }

  static async createOrder(data: ICreateOrder): Promise<IResponse<any>> {
    return fetcher(`/product-orders`, {
      method: 'POST',
      data: data,
    })
  }

  static async updateOrder(id: string, data: any): Promise<IResponse<any>> {
    return fetcher(`/product-orders/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static async deleteOrder(id: string): Promise<IResponse<any>> {
    return fetcher(`/product-orders/${id}`, {
      method: 'DELETE',
    })
  }
}
