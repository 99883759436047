import ActionCell from 'src/components/base/action/ActionCell'

interface IProps {
  handleDelete: () => void
  handleEdit: () => void
}
const SaleProductAction = ({ handleDelete, handleEdit }: IProps) => {
  return (
    <ActionCell customWidth='w-150px'>
      <div className='menu-item px-3'>
        <div className='menu-link px-3' onClick={handleEdit}>
          Edit
        </div>
      </div>
      <div className='menu-item px-3'>
        <div className='menu-link px-3' onClick={handleDelete}>
          Delete
        </div>
      </div>
    </ActionCell>
  )
}

export default SaleProductAction
