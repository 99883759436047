import _ from 'lodash'

// Combined reusable function
export const processFields = <T>(
  array: T[],
  key: keyof T,
  field?: keyof T[keyof T],
  unique = true
) => {
  // Step 1: Extract the values from the array
  const values = array?.reduce((acc: string[], curr: T) => {
    const value = field ? curr?.[key]?.[field] : curr?.[key]
    return value ? [...acc, String(value)] : acc
  }, [])

  // Step 2: Handle uniqueness if needed
  const processedValues = unique ? _.uniq(values) : values

  // Step 3: Join the values or return fallback
  return processedValues
}

type ProgramOrder = string[]

export const groupByOrder = <T extends Record<string, any>>(
  data: T[],
  order: ProgramOrder,
  key: Extract<keyof T, string> // Ensures the key points to string values
): T[][] => {
  return order
    .map((program) => data.filter((item) => item[key] === program))
    .filter((group) => group.length > 0)
}
