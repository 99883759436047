import { useEffect, useMemo } from 'react'
import { usersColumns } from './columns/_columns'
import { useTable } from 'react-table'
import { KTCardBody } from 'src/_metronic/helpers'
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseDataPagination,
} from '../core/QueryResponseProvider'
import { isEmpty } from 'lodash'
import { useQueryRequest } from '../core/QueryRequestProvider'
import LoadingTable from 'src/common/LoadingTable'
import { IPaginationUser } from 'src/type/students'
import PaginationSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import { MOCKUP_HEADER, STAFF_PROFILE, STATUS_FORM } from 'src/constants'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import UserCell from 'src/components/user-management/UserCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import AssignedTo from 'src/components/base/assigned'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import { StaffActionsCell } from './columns/StaffActionsCell'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { useForm } from 'react-hook-form'
import { useConfirm } from 'src/hooks/use-confirm'
import { Select } from 'antd'
import { UsersAPI } from 'src/apis/user'
import { toast } from 'react-hot-toast'
import { EStatusUser, Role } from 'src/type'
import { formatDate, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_STAFF_GR } from 'src/constants/permission'
const { Option } = Select

const StaffTable = ({
  currentPage,
  pageSize,
  textSearch,
  sortRole,
  sortStatus,
  sortType,
  fromDate,
  toDate,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  sex,
}: IPaginationUser) => {
  const { isLoading, refetch } = useQueryResponse()
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { rows } = useTable({ columns, data })
  const pagination = useQueryResponseDataPagination()
  const { updateState } = useQueryRequest()
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()

  // TODO: reset lại table về mặc định nếu không call API
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(fromDate as Date)
  const dateQueryToDate = getDateInfo(toDate as Date)

  const existFilter =
    !isEmpty(sortRole) ||
    !isEmpty(textSearch) ||
    !isEmpty(sortStatus) ||
    !isEmpty(fromDate) ||
    !isEmpty(toDate) ||
    !isEmpty(sortType)

  useEffect(() => {
    updateState({
      page_size: pageSize,
      page_index: currentPage,
      text: textSearch,
      role_code: sortRole,
      status: sortStatus,
      sortType: sortType,
      fromDate: fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      toDate: toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      dateField: existFilter ? 'updated_at' : '',
      sex: sex,
    })
  }, [])

  // Using validate for input
  const { control, watch, setValue } = useForm<any>({
    mode: 'onChange',
  })

  const changeStatusUser = (id: string, status: any) => {
    UsersAPI.updateStaffStatus({ id, status })
      .then(() => toast.success('Update Successfully!'))
      .catch((error) => {
        // if (error?.response?.data?.error?.code === '403|000000') {
        //   toast.error(DENIED_PERMISSIONS)
        // }
      })
  }

  const hanleChangeStatus = (id: string, value: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: () =>
        changeStatusUser(
          id,
          EStatusUser.ACTIVE === value
            ? EStatusUser.ACTIVE
            : EStatusUser.INACTIVE === value
            ? EStatusUser.INACTIVE
            : EStatusUser.BLOCKED
        ),
      onClose: () => cancel(true),
    })
  }

  const handleChange = (name: string, value: string, id: string) => {
    if (watch(name)) {
      hanleChangeStatus(id, value)
    }
  }

  useEffect(() => {
    rows
      ?.map((staff: any) => ({
        label: staff?.original?.status?.toLocaleLowerCase(),
        value: staff?.original?.status,
      }))
      ?.forEach((option, index) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, rows])

  // TODO: dùng để đổi params trên router
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: textSearch ?? '',
      role: sortRole ?? '',
      status: sortStatus ?? '',
      sortType: sortType ?? '',
      fromDate: formatDate(fromDate as Date) ?? '',
      toDate: formatDate(toDate as Date) ?? '',
      sex: sex ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    updateState({
      page_size: page_size,
      page_index: page_index,
      text: textSearch ?? '',
      status: sortStatus ?? '',
      role_code: sortRole ?? '',
      sortType: sortType ?? '',
      fromDate: fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      toDate: toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      dateField: existFilter ? 'updated_at' : 'created_at',
    })
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }
  const { profileMe } = useUserContext()
  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderRole = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.GET_ASSIGN_PERMISSION_STAFF
  )

  const allowRenderResetPasswordStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.RESET_PASSWORD_STAFF
  )
  const allowRenderBlockStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.EDIT_STAFF)
  const allowRenderEditEmailStaff = hasPermission(
    profileMe?.roles,
    TITLE_STAFF_GR.CHANGE_EMAIL_STAFF
  )
  const allowRenderViewProfileStaff = hasPermission(profileMe?.roles, TITLE_STAFF_GR.GET_STAFF)
  const allowRenderStaffActions =
    allowRenderEdit ||
    allowRenderResetPasswordStaff ||
    allowRenderBlockStaff ||
    allowRenderEditEmailStaff ||
    allowRenderViewProfileStaff

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={[
          { label: 'code' },
          { label: 'user' },
          { label: 'username' },
          { label: 'phone' },
          ...(allowRenderRole ? [{ label: 'role' }] : []),
          { label: 'status' },
          { label: 'date' },
        ]}
        loading={isLoading}
        data={rows}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {isLoading ? (
          <>
            {MOCKUP_HEADER.map((_header, i) => (
              <LoadingTable key={i} headers={MOCKUP_HEADER} />
            ))}
          </>
        ) : (
          <>
            {rows?.map((staff: any, index) => {
              const isChecked = checkedList.includes(staff?.original?.id)
              return (
                <tr key={staff?.original?.id ?? index}>
                  <td className='min-w-50px'>
                    <SAPPCheckbox
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(staff?.original?.id)
                      }}
                    />
                  </td>
                  <td className='min-w-100px'>
                    <UserCell data={staff?.original?.key} />
                  </td>
                  <td className='min-w-250px'>
                    <UserInfoCell
                      user={staff?.original}
                      linkViewProfile={`${STAFF_PROFILE}/${staff?.original.id}/overview`}
                    />
                  </td>
                  <td>
                    <UserCell data={staff?.original?.username} />
                  </td>
                  <td>
                    <UserCell data={staff?.original?.detail?.phone} />
                  </td>
                  {allowRenderRole && (
                    <td className='min-w-250px'>
                      <AssignedTo roles={staff?.original?.roles} />
                    </td>
                  )}
                  <td className='min-w-175px'>
                    <HookFormSelectAntd
                      allowClear={false}
                      size='large'
                      name={`status${index}`}
                      control={control}
                      dropdownStyle={{ maxWidth: 100 }}
                      placeholder='Status'
                      filterOption={true}
                      disabled={!allowRenderEdit || profileMe?.id === staff?.original?.id}
                      onChange={(selectedValue: unknown) =>
                        handleChange(`status${index}`, selectedValue as string, staff?.original?.id)
                      }
                      classNameHeight='sapp-h-35px'
                    >
                      {STATUS_FORM.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </td>
                  <td className='min-w-250px'>
                    <DatetimeColumn
                      updated_at={staff?.original?.updated_at}
                      created_at={staff?.original?.created_at}
                    />
                  </td>
                  {allowRenderStaffActions && (
                    <td className='text-center sapp-absolute-column'>
                      <StaffActionsCell
                        id={staff?.original?.id}
                        status={staff?.original?.status}
                        email={staff?.original?.detail?.email}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PaginationSAPP
        currentPage={pagination?.data?.metadata?.page_index || 1}
        pageSize={pagination?.data?.metadata?.page_size || 10}
        totalItems={pagination?.data?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export { StaffTable }
