import { Collapse, ConfigProvider, Skeleton } from 'antd'
import clsx from 'clsx'
import { useQuery } from 'react-query'
import { UsersAPI } from 'src/apis/user'
import PermissionInfo from 'src/components/roles/detail/PermissionInfo'
import { IMyRole } from 'src/type/profile'
import styles from './myRole.module.scss'

const MyRole = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['profile-my-role'],
    queryFn: async () => {
      const { data } = await UsersAPI.getMyRole()
      return data
    },
    refetchOnWindowFocus: false,
  })

  const groupedData: Record<string, IMyRole[]> = (data || []).reduce(
    (acc: Record<string, IMyRole[]>, item) => {
      if (!acc[item.group]) {
        acc[item.group] = []
      }
      acc[item.group].push({
        key: item.key,
        display_name: item.display_name,
        description: item.description,
        group: item.group,
      })
      return acc
    },
    {}
  )
  const workflowLink =
    'https://workflow.base.vn/it06captaikhoanxoataikhoancapquyentruycapthaydoiquyencachethongitquanly-10676'

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            /* here is your component tokens */
            contentBg: '',
            headerBg: '',
          },
        },
      }}
    >
      <div className='card mb-5 mb-xl-10'>
        <div className={`card-header align-items-center`}>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>My Role</h3>
          </div>
          <p className='mb-0'>
            Các vấn đề về tài khoản và quyền, đề xuất qua{' '}
            <a href={workflowLink} className={styles.workflow} target='_blank'>
              Workflow
            </a>
            .
          </p>
        </div>
        <div className='card-body'>
          {isLoading && <Skeleton />}
          <div className='collapse show'>
            {Object.entries(groupedData).map(([groupName, items]) => (
              <Collapse
                bordered={false}
                expandIconPosition='right'
                items={[
                  {
                    key: '1',
                    label: (
                      <label className='fs-6 fw-semibold form-label mb-0 text-capitalize text-gray-600'>
                        {groupName.replace(/(_management)|_/g, ' ')} ({items.length})
                      </label>
                    ),
                    children: (
                      <div className={styles.permissionInfoContainer}>
                        {items.map((item) => (
                          <PermissionInfo key={item.key} title={item.display_name} />
                        ))}
                      </div>
                    ),
                  },
                ]}
                className={clsx(styles.collapse)}
              />
            ))}
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default MyRole
