import React, { FC } from 'react'

interface DownloadProps extends React.SVGProps<SVGSVGElement> {}
const Download: FC<DownloadProps> = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' {...props}>
      <path
        fill='currentColor'
        d='M17.5 15v-2.5a.624.624 0 1 0-1.25 0V15a.624.624 0 0 1-.625.625H4.375A.625.625 0 0 1 3.75 15v-2.5a.625.625 0 1 0-1.25 0V15a1.875 1.875 0 0 0 1.875 1.875h11.25A1.875 1.875 0 0 0 17.5 15Zm-3.988-3.262-3.125 2.5a.625.625 0 0 1-.774 0l-3.125-2.5a.625.625 0 0 1 .774-.976l2.113 1.688v-8.7a.625.625 0 0 1 1.25 0v8.7l2.113-1.688a.625.625 0 1 1 .774.976Z'
      />
    </svg>
  )
}

export default Download
