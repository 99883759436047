import { Select } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  Control,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import CustomTagsWithPlus from 'src/components/base/tag/CustomTagsWithPlus'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { PageLink } from 'src/constants'
import { LANG_ORDERS } from 'src/constants/lang'
import {
  OPTIONS_PAYMENT_METHOD_STATUS,
  PAYMENT_METHOD,
  PAYMENT_METHOD_STATUS,
  TITLES_PAYMENT_METHOD,
} from 'src/constants/sales'
import { useConfirm } from 'src/hooks/use-confirm'
import { IStep } from 'src/pages/shop/faqs/FaqDetail'
import { ICreateOrder, IProvince } from 'src/type/sales/orders'
import { formatPrice, parsePrice } from 'src/utils'

const { Option } = Select

interface IProp {
  step: IStep[]
  setStep: Dispatch<SetStateAction<IStep[]>>
  control: Control<ICreateOrder, any>
  watch: UseFormWatch<ICreateOrder>
  setValue: UseFormSetValue<ICreateOrder>
  getValues: UseFormGetValues<ICreateOrder>
  handleSubmit: UseFormHandleSubmit<ICreateOrder>
  setIsProductModal: Dispatch<SetStateAction<boolean>>
  setIsPromotionCodesModal: Dispatch<SetStateAction<boolean>>
  products: Array<any>
  setProducts: Dispatch<SetStateAction<Array<any>>>
  promotionCodes: Array<any>
  setPromotionCodes: Dispatch<SetStateAction<Array<any>>>
  isLoading: boolean
  provinces: Array<any>
  calculateTotalPrice: () => void
}

const CreateOrderTab = ({
  step,
  setStep,
  control,
  watch,
  setValue,
  getValues,
  handleSubmit,
  setIsProductModal,
  setIsPromotionCodesModal,
  products,
  setProducts,
  promotionCodes,
  setPromotionCodes,
  isLoading,
  provinces,
  calculateTotalPrice,
}: IProp) => {
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.OPERATIONS_SALES_ORDERS),
    })
  }

  const handleNextStep = () => {
    setStep((prev: IStep[]) => {
      const index = prev.findIndex((item) => item.status === 'current')
      return prev.map((item: IStep, idx: number) => ({
        ...item,
        status: idx === index + 1 ? 'current' : idx < index + 1 ? 'active' : '',
      }))
    })
  }

  const onDeselectProduct = (value: string) => {
    setProducts(products.filter((item) => item.id !== value))
  }

  const onDeselectPromotionCode = (value: string) => {
    setPromotionCodes(promotionCodes.filter((item) => item.id !== value))
  }

  return (
    <div className='form d-flex flex-column flex-lg-row gap-7'>
      {contextHolder}
      <div className='d-flex justify-content-center'>
        <Processbar
          step={step}
          setNewStep={setStep}
          cancelButtonCaption='Cancel'
          showCancel
          onCancel={handleCancel}
          onClick={handleSubmit(handleNextStep)}
          okButtonCaption='Next'
          className='mw-500px'
        />
      </div>

      <div className='d-flex flex-column gap-7 flex-grow-1'>
        <div className='card card-flush'>
          <div className='card-body pt-0'>
            <SappLabel label='Customer Info' isLabel required className='sapp-mt-32px' />

            <Row>
              <Col className='sapp-mb-24px'>
                <HookFormTextField
                  control={control}
                  name='fullName'
                  label={LANG_ORDERS.name}
                  placeholder=''
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} className='sapp-mb-24px'>
                <HookFormTextField
                  control={control}
                  name='email'
                  label={LANG_ORDERS.email}
                  placeholder=''
                  required
                />
              </Col>
              <Col md={6} className='sapp-mb-24px'>
                <HookFormTextField
                  control={control}
                  name='phoneNumber'
                  label={LANG_ORDERS.phone}
                  placeholder=''
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className='sapp-mb-24px'>
                <HookFormSelectAntd
                  control={control}
                  name='province'
                  label={LANG_ORDERS.province}
                  placeholder=''
                  showSearch
                  classNameHeight='sapp-h-45px'
                  required
                >
                  {provinces?.map((province: IProvince) => (
                    <Option key={province.code} value={province.name}>
                      {province.name}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </Col>
            </Row>

            <Row>
              <Col className='sapp-mb-24px'>
                <HookFormTextField
                  control={control}
                  name='address'
                  label={LANG_ORDERS.address}
                  placeholder=''
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <HookFormTextField
                  control={control}
                  name='deal_id'
                  label={LANG_ORDERS.dealID}
                  placeholder=''
                  required
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className='card card-flush'>
          <div className='card-body pt-0'>
            <SappLabel label={LANG_ORDERS.productInfo} isLabel required className='sapp-mt-32px' />

            <Row>
              <Col className='sapp-mb-24px'>
                <HookFormTextField
                  control={control}
                  name='name'
                  label={LANG_ORDERS.orderName}
                  placeholder=''
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className='sapp-mb-24px'>
                <CustomTagsWithPlus
                  control={control}
                  name='product_ids'
                  label={LANG_ORDERS.products}
                  onClick={() => setIsProductModal(true)}
                  selectOptions={products.map((item) => ({
                    value: item.id,
                    name: item.name,
                  }))}
                  onDeselect={onDeselectProduct}
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className='sapp-mb-24px'>
                <HookFormTextField
                  control={control}
                  type='text'
                  name='price'
                  label={LANG_ORDERS.price}
                  placeholder=''
                  postFix={<div className='ps-3 pe-3 text-gray-700'>đ</div>}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row>
              <Col className='sapp-mb-24px'>
                <HookFormTextField
                  control={control}
                  type='text'
                  name='deposit'
                  label={LANG_ORDERS.deposit}
                  placeholder=''
                  postFix={<div className='ps-3 pe-3 text-gray-700'>đ</div>}
                  onChange={() => {
                    setValue('deposit', formatPrice(parsePrice(String(getValues('deposit')))))
                    calculateTotalPrice()
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <CustomTagsWithPlus
                  control={control}
                  name='promotion_code_ids'
                  label={LANG_ORDERS.promotionCode}
                  onClick={() => setIsPromotionCodesModal(true)}
                  selectOptions={promotionCodes.map((item) => ({
                    value: item.id,
                    name: item.code,
                  }))}
                  onDeselect={onDeselectPromotionCode}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className='d-flex flex-column gap-7 w-100 w-lg-300px'>
        <div className='card card-flush'>
          <div className='card-body pt-0'>
            <SappLabel
              label={LANG_ORDERS.paymentMethod}
              isLabel
              required
              className='sapp-mt-32px'
            />

            <Row>
              <Col className='sapp-mb-24px'>
                <SappLabel label={LANG_ORDERS.paymentMethodStatus} required />
                <HookFormRadioGroup
                  control={control}
                  name='paymentMethodStatus'
                  direction='horizontal'
                  separator={false}
                  justify='start'
                  gap={10}
                  labelClass='fw-semibold fs-6 sapp-table-class-field'
                  options={OPTIONS_PAYMENT_METHOD_STATUS}
                  defaultValue={PAYMENT_METHOD_STATUS.YES}
                />
              </Col>
            </Row>

            <Row>
              <Col className='sapp-mb-24px'>
                <HookFormSelectAntd
                  control={control}
                  name='paymentMethodName'
                  label={LANG_ORDERS.paymentMethodName}
                  placeholder=''
                  showSearch
                  classNameHeight='sapp-h-45px'
                  defaultValue={PAYMENT_METHOD.DIRECT_PAYMENT}
                  required
                >
                  <Option value={PAYMENT_METHOD.DIRECT_PAYMENT}>
                    {TITLES_PAYMENT_METHOD.DIRECT_PAYMENT}
                  </Option>
                  <Option value={PAYMENT_METHOD.INSTALLMENT_PAYMENT}>
                    {TITLES_PAYMENT_METHOD.INSTALLMENT_PAYMENT}
                  </Option>
                </HookFormSelectAntd>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateOrderTab
