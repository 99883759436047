import { Dispatch, SetStateAction } from 'react'
import { IMetadata } from './question-bank'
import { Abstract } from './abstract'
import { ISubject } from './subject'

export interface ICategories {
  code: string
  created_at: Date
  deleted_at: Date
  description: string
  id: string
  name: string
  updated_at: Date
}

export interface ICourse {
  subject: ISubject
  code: string
  course_categories: ICategories[]
  course_difficulty: number
  course_image: string | null
  course_type: 'NORMAL_COURSE' | 'TRIAL_COURSE' | 'FOUNDATION_COURSE' | 'PRACTICE_COURSE'
  created_at: Date
  created_by: any
  created_from: any
  deleted_at: Date
  description: string
  duration: number
  id: string
  name: string
  pass_point: number
  published_at: Date
  status: 'DRAFT' | 'PUBLISH' | 'LOCK'
  template: number
  updated_at: Date
  setting: {
    activity_points: number
    final_test_points: number
    mid_term_points: number
  }
}

export interface ICourseList {
  courses: ICourse[]
  metadata: IMetadata | undefined
}

export interface ICourseCategory {
  code: string
  created_at: Date
  deleted_at: Date
  description: string
  id: string
  name: string
  updated_at: Date
  subject_instances: SubjectInstance[]
}
export interface ICourseCategoies {
  course_categories: ICourseCategory[]
  metadata: IMetadata
}
export interface SubjectInstance {
  id: string
  created_at: string
  updated_at: string
  deleted_at: any
  course_category_id: string
  name: string
  code: string
  feature_section_instances: FeatureSectionInstance[]
}

export interface FeatureSectionInstance {
  id: string
  created_at: string
  updated_at: string
  deleted_at: any
  name: string
  code: string
  subject_id: string
}

export interface ICoursePart {
  name?: string
  short_name?: string
  code?: string
  description?: string
  course_id?: string
  parent_id?: string
  position?: number
  text_editor_files?: Array<{ id: string }>
}
export interface ICourseSession {
  code?: string
  name?: string
  short_name?: string
  description?: string
  outcomes?: string[]
  course_section_id?: string
  position?: number
  course_id?: string
  text_editor_files?: Array<{ id: string }>
}
export interface ICourseLearnOutCome {
  id?: string
  name?: string
  description?: string
  outcomes?: { uuid?: string; id?: string; description: string }[]
  course_section_id?: string
  remove_outcome_ids?: (string | undefined)[]
  text_editor_files?: Array<{ id: string }>
}
export interface ICourseLearnOutComeUpdate {
  name?: string
  description?: string
  outcomes?: string[]
  course_section?: string
  id: string
}

export enum CourseStudentStatus {
  NOT_IN_CLASS = 'IN_CLASS',
  EMPTY = 'EMPTY_STUDENT',
  CONTAIN_STUDENT_BUT_NOT_LEARNED = 'CONTAIN_STUDENT_BUT_NOT_LEARNED',
  CONTAIN_STUDENT_AND_NOT_COMPLETED = 'CONTAIN_STUDENT_AND_NOT_COMPLETED',
  CONTAIN_STUDENT_AND_RECEIVED_CERT = 'CONTAIN_STUDENT_AND_RECEIVED_CERT',
  CONTAIN_STUDENT_AND_COMPLETED_ALL = 'CONTAIN_STUDENT_AND_COMPLETED_ALL',
}

export class CourseState {
  constructor() {
    this.student_status = []
    this.condition = new CourseCondition()
  }
  student_status: Array<CourseStudentStatus>
  condition: CourseCondition
}
export class CourseCondition {
  constructor() {
    this.can_edit_course_info = {
      name: true,
      description: true,
      code: true,
      course_category_ids: true,
      subject_id: true,
      course_tags: true,
      course_type: true,
      pass_point: true,
      setting: true,
      status: true,
      thumbnail: true,
      duration: true,
      course_levels: true,
      course_connect_ia: true,
      course_connect_ib: true,
      course_connect_iia: true,
      course_connect_iib: true,
    }
    this.can_edit_course_content = {
      can_add_quiz: true,
      can_edit_quiz: true,
      can_delete_quiz: true,

      can_add_learning_outcome: true,
      can_add_section: true,
      can_add_subsection: true,
      can_add_unit: true,
      can_add_activity: true,
      can_add_tab: true,

      can_delete_section: true,

      can_edit_section: true,
      can_edit_subsection: true,
      can_edit_unit: true,
      can_edit_activity: true,
      can_edit_tab: true,

      can_remove_link: true,
      can_drag_drop_course_section: true,
      can_link_course_section: true,
    }
    this.can_delete_course = true

    this.can_delete_course = true
    this.can_edit_resource = {
      add_resource: true,
      edit_resource: true,
      delete_resource: true,
    }
    this.can_edit_certificate = {
      add_certificate: true,
      edit_certificate: true,
      delete_certificate: true,
    }
  }

  can_delete_course: boolean
  can_edit_course_info: {
    name: boolean
    description: boolean
    code: boolean
    course_category_ids: boolean
    subject_id: boolean
    course_tags: boolean
    course_type: boolean
    pass_point: boolean
    setting: boolean
    status: boolean
    thumbnail: boolean
    duration: boolean
    course_levels: boolean
    course_connect_ia: boolean
    course_connect_ib: boolean
    course_connect_iia: boolean
    course_connect_iib: boolean
  }
  can_edit_course_content: {
    can_add_quiz: boolean
    can_edit_quiz: boolean

    can_delete_quiz: boolean

    can_add_learning_outcome: boolean
    can_add_section: boolean
    can_add_subsection: boolean
    can_add_unit: boolean
    can_add_activity: boolean
    can_add_tab: boolean

    can_delete_section: boolean

    can_edit_section: boolean
    can_edit_subsection: boolean
    can_edit_unit: boolean
    can_edit_activity: boolean
    can_edit_tab: boolean
    can_drag_drop_course_section: boolean

    can_link_course_section: boolean
    can_remove_link: boolean
  }
  can_edit_resource: {
    add_resource: boolean
    edit_resource: boolean
    delete_resource: boolean
  }
  can_edit_certificate: {
    add_certificate: boolean
    edit_certificate: boolean
    delete_certificate: boolean
  }
}

export interface ICourseInfo {
  [key: string]: any
  id?: string
  course_info: File
  code: string
  name: string
  course_category_ids?: string[]
  course_categories?: ICourseCategory[]
  course_tags?: ICourseTags[] | Array<string>
  duration: number
  course_type?: ICourseType
  normal_course_connections?: any[]
  files?: any[]
  template?: number
  levelIA: string
  levelIIA: string
  levelIB: string
  levelIIB: string
  connectIA: string
  connectIIA: string
  levels?: ICourseLevel[]
  setting?: {
    [key: string]: any
    progress: number
    activity_points: number
    chapter_points: number
    topic_points: number
    mid_term_points: number
    final_test_points: number
  }
  connectIB: string
  connectIIB: string
  activity_points: number
  mid_term_points: number
  final_test_points: number
  pass_point: string
  description: string
  total_point?: number
  subject_id: string
  topic_points: number
  status: any
  progress: number
  chapter_points: number
  course_image: any
  course_connect_ia: boolean
  course_connect_ib: boolean
  course_connect_iia: boolean
  course_connect_iib: boolean
  state?: CourseState
}

export interface ICourseLevel {
  code: string
  description: string
  id: string
  name: string
}

export enum ECourseLevel {
  IA = 'IA',
  IB = 'IB',
  IIA = 'IIA',
  IIB = 'IIB',
}

export interface ICourseTags {
  created_at: Date
  deleted_at: Date | null
  id: string
  name: string
  updated_at: Date
}
export interface ICourseActivity {
  name?: string
  code?: string
  description?: string
  parent_id?: string
  course_learning_outcome_id?: string
  course_outcome_ids?: string[]
  duration?: number
  is_graded?: boolean
  score?: number
  resource_ids?: []
  display_icon: string
  course_id: string
}
export interface ICourseActivityEdit {
  id: string
  data: ICourseActivity
}
export interface ICourseTab {
  id?: string
  course_section_id: string
  tab_name: string
  course_id?: string
}

export enum ICourseType {
  FOUNDATION = 'FOUNDATION_COURSE',
  TRIAL = 'TRIAL_COURSE',
  PRACTICE = 'PRACTICE_COURSE',
  NORMAL = 'NORMAL_COURSE',
}
export enum IClassType {
  TRIAL = 'TRIAL',
  LESSON = 'LESSON',
  REVISION = 'REVISION',
  FOUNDATION = 'FOUNDATION',
}
export enum EStatusCourse {
  DRAFT = 'DRAFT',
  PUBLISH = 'PUBLISH',
  LOCK = 'LOCK',
  BLOCK = 'BLOCK',
}

export enum EEntranceTest {
  DRAFT = 'DRAFT',
  PUBLISH = 'PUBLISHED',
  LOCK = 'LOCKED',
}

export interface IChapterChildrenProps {
  activity_type: string
  code: string
  course_id: string
  course_learning_outcome: any
  course_outcomes: Array<any>
  course_section_type: any
  created_at: Date
  deleted_at: Date
  description: string
  display_icon: string
  duration: number
  id: string
  is_graded: boolean
  is_peer_review: boolean
  is_public: boolean
  name: string
  parent: string
  parent_id: string
  position: string
  status: string
  updated_at: Date
}

export interface IChapterProps {
  children: IChapterChildrenProps[]
  activity_type: string
  code: string
  course_id: string
  course_learning_outcome: any
  course_outcomes: Array<any>
  course_section_type: any
  created_at: Date
  deleted_at: Date
  description: string
  display_icon: string
  duration: number
  id: string
  is_graded: boolean
  is_peer_review: boolean
  is_public: boolean
  name: string
  parent: string
  parent_id: string
  position: string
  status: string
  updated_at: Date
}
export enum COURSE_SECTION_TYPES {
  TOPIC_TEST = 'TOPIC_TEST',
  CHAPTER_TEST = 'CHAPTER_TEST',
  CASE_STUDY_STORY = 'STORY',
  PART = 'PART',
  CHAPTER = 'CHAPTER',
  UNIT = 'UNIT',
  ACTIVITY = 'ACTIVITY',
  FINAL_TEST = 'FINAL_TEST',
  MID_TERM_TEST = 'MID_TERM_TEST',
  TAB = 'TAB',
}
export class CourseSection extends Abstract {
  id = ''
  name = ''
  code = ''
  description = ''
  status = ''
  is_public = false
  duration = ''
  is_peer_review = true
  is_graded = true
  course_section_type: COURSE_SECTION_TYPES = COURSE_SECTION_TYPES.PART
  activity_type = ''
  position = 0
  display_icon = ''
  quiz_id = ''
  course_id = ''

  constructor(entity?: Partial<CourseSection>) {
    super(entity)
    Object.assign(this, entity)
  }
}

export interface ICourseSectionList {
  sections: CourseSection[]
  meta: IMetadata
}

export interface ICourseLink {
  open: boolean
  setOpen: Dispatch<SetStateAction<any>>
  position: number
  body: string
  title: string
  parentId?: string | undefined
}

export interface IInputCourseLink {
  courseId: string
  course_section: Array<{ course_section_ids: boolean }>
  partId?: string
  chapterId?: string
  unitId?: string
}

export enum CourseNameLevel {
  LEVEL = 'level',
  CMA = 'cma_level',
  ACCA = 'acca_level',
}

export interface ITabActivity {
  id?: string
  created_at: Date
  updated_at: Date
  deleted_at?: Date
  type?: string
  text_editor_content?: string
  course_section_id?: string
  position: number
  files: any
}

export enum PROGRAM {
  ACCA = 'ACCA',
  CFA = 'CFA',
  CMA = 'CMA',
  CERT_DIP = 'Cert/Dip',
}

export enum SUBJECT_CODE {
  CFA_LEVEL_1 = 'CFA-Level-1',
  CFA_LEVEL_2 = 'CFA-Level-2',
  CFA_LEVEL_3 = 'CFA-Level-3',
}

export interface ICourseSubject {
  id: string
  name: string
  code?: string
  course_category?: {
    name: string
  }
  course_category_id: string
  created_at?: Date
  updated_at?: Date
}

export interface ISubjectDetail {
  id: string
  name: string
  code?: string
  course_category_id: string
  created_at?: Date
  updated_at?: Date
}

export interface ICourseSubjectList {
  meta: IMetadata
  subjects: ICourseSubject[]
}
