import { useLocation, useParams } from 'react-router-dom'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { IPromotionCode } from 'src/type/sales/promotionCodes'
import OrderDetail from './OrderDetail'
import DetailPromotionCode from './OverviewDetail'

interface IProps {
  promotionCode: IPromotionCode | null
  loading: boolean
}

const BodyDetail = ({ promotionCode, loading }: IProps) => {
  const location = useLocation()
  const { id } = useParams()

  const renderClass = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case 'overview':
        return <DetailPromotionCode promotionCode={promotionCode} />
      case 'list-orders':
        return <OrderDetail promotionCode={promotionCode} />
      default:
        return <DetailPromotionCode promotionCode={promotionCode} />
    }
  }
  return loading ? <LoadingProfileDetail loadingAvatar={false} /> : renderClass()
}

export default BodyDetail
