import { Skeleton } from 'antd'
import SappTabs from 'src/components/tabs/SappTabs'
import { ITabs } from 'src/type'
import { IPromotionCode } from 'src/type/sales/promotionCodes'
import { formatDate } from 'src/utils'

interface IProps {
  promotionCode: IPromotionCode | null
  tabs: ITabs[]
  loading: boolean
}

const HeaderDetail = ({ promotionCode, tabs, loading }: IProps) => {
  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='pt-10 px-10 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start mb-9'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-5'>
                  {loading ? (
                    <Skeleton.Button active block />
                  ) : (
                    <div className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                      {promotionCode?.code}
                    </div>
                  )}
                </div>

                <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                  <div className='d-flex align-items-center me-5 mb-2'>
                    {loading ? (
                      <Skeleton.Button active block size='small' />
                    ) : (
                      <div
                        className={`sapp-class-icon sapp-class-icon-public f-flex justify-content-center min-w-44px`}
                      >
                        {promotionCode?.status}
                      </div>
                    )}
                  </div>
                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    {loading ? (
                      <Skeleton.Button active block size='small' />
                    ) : (
                      <>
                        <i className='ki-duotone ki-geolocation fs-4 me-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        {promotionCode?.used_promotion_count ?? 'Unknown'}
                        {' / '}
                        {promotionCode?.quantity}
                      </>
                    )}
                  </div>

                  <div className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                    {loading ? (
                      <Skeleton.Button active block size='small' />
                    ) : (
                      <>
                        <i className='ki-duotone ki-calendar fs-4 me-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        <span className='d-flex align-items-center'>
                          {formatDate(promotionCode?.start_date, 'dd/MM/yyyy')}
                          {' - '}
                          {formatDate(promotionCode?.end_date, 'dd/MM/yyyy')}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Tabs */}
        {loading ? <Skeleton.Button active block /> : <SappTabs tabs={tabs} />}
        {/* End Tabs */}
      </div>
    </div>
  )
}

export default HeaderDetail
