import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { PromotionCodeAPI } from 'src/apis/sales/promotion-codes'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappFilterButton from 'src/common/SAPPFIlterButton'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import SappModal from 'src/components/base/SappModal'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import { LANG_PROMOTION_CODE } from 'src/constants/lang'
import { OPTIONS_DISCOUNT_TYPE, OPTIONS_PROMOTION_CODE_STATUS } from 'src/constants/sales'
import useChecked from 'src/hooks/use-checked'
import { IPromotionCodeFilter, IPromotionCodes } from 'src/type/sales/promotionCodes'
import { cleanParamsAPI, formatISOFromDate, getDateInfo } from 'src/utils'
import { z } from 'zod'
import CombinedPromotionCodeTable from './CombinedPromotionCodeTable'

const { Option } = Select

interface IProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  combinedPromotionCodes: Array<any>
  setCombinedPromotionCodes: Dispatch<SetStateAction<Array<any>>>
}

const CombinedPromotionCodeModal = ({
  isOpen,
  setIsOpen,
  combinedPromotionCodes,
  setCombinedPromotionCodes,
}: IProps) => {
  const [data, setData] = useState<IPromotionCodes>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()
  const {
    checkedList,
    listDataChecked,
    setDefaultChecked,
    toggleCheck,
    toggleCheckAll,
    isCheckedAll,
  } = useChecked<any>(data?.promotionCodes)

  const validation = z.object({
    code: z.string().optional(),
    category: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
  })

  const { control, handleSubmit, getValues, reset } = useForm<IPromotionCodeFilter>({
    resolver: zodResolver(validation),
    mode: 'onSubmit',
  })

  useEffect(() => {
    fetchPromotionCodes(1, 10)
  }, [])

  useEffect(() => {
    setDefaultChecked(combinedPromotionCodes)
  }, [combinedPromotionCodes])

  const fetchPromotionCodes = useCallback(
    async (pageIndex: number, pageSize: number, params?: Object) => {
      setIsLoading(true)
      try {
        const res = await PromotionCodeAPI.getPromotionCodes({
          page_index: pageIndex,
          page_size: pageSize,
          params,
        })
        setData(res?.data)
      } catch (error) {
        // do nothing
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, setData]
  )

  const handleCancel = () => {
    setIsOpen(false)
  }

  const handleSave = () => {
    setCombinedPromotionCodes(listDataChecked)
    setIsOpen(false)
  }

  const getParams = () => {
    const inputRangeDate = getValues('rangeDate')
    const dateInfoFromDate = inputRangeDate?.fromDate ? getDateInfo(inputRangeDate.fromDate) : null
    const dateInfoToDate = inputRangeDate?.toDate ? getDateInfo(inputRangeDate.toDate) : null

    return {
      code: getValues('code'),
      category: getValues('category'),
      status: getValues('status'),
      sortType: getValues('sortType'),
      startDate: dateInfoFromDate
        ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
        : '',
      endDate: dateInfoToDate
        ? formatISOFromDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
        : '',
    }
  }

  const handleChangeParams = (pageIndex: number, pageSize: number, params?: Object) => {
    const queryParams = {
      page_index: pageIndex,
      page_size: pageSize,
      ...params,
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${encodeURI(queryString)}`)
  }

  const handleFilter = () => {
    const pageIndex = parseInt(searchParams.get('page_index') || '1')
    const pageSize = parseInt(searchParams.get('page_size') || '10')
    const cleanedParams = cleanParamsAPI(getParams())

    handleChangeParams(pageIndex, pageSize, cleanedParams)
    fetchPromotionCodes(pageIndex, pageSize, cleanedParams)
  }

  const handleResetFilter = () => {
    reset()
    navigate('')
    fetchPromotionCodes(1, 10)
  }

  return (
    <SappModal
      title=''
      hideHeader
      open={isOpen}
      handleClose={handleCancel}
      dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
      classNameBody={'sapp-m-h-unset'}
      showFooter={false}
    >
      <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
        <div className='sapp-title-modal-create-class fs-3'>{LANG_PROMOTION_CODE.combinedWith}</div>
        <div className='d-flex justify-content-between align-items-center'>
          {listDataChecked.length > 0 && (
            <div className='fw-medium fs-6 text-primary me-8'>
              {listDataChecked.length} Selected
            </div>
          )}
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleCancel}
            cancelButtonCaption='Cancel'
            okButtonCaption='Add'
            okOnClick={handleSave}
            className='justify-content-between d-flex flex-row-reverse'
            classNameCancel='fw-semibold sapp-fs-14 me-0 btn-sapp-filter me-3 btn btn-primary'
            classNameSubmit='fw-semibold sapp-fs-14 me-5'
          />
        </div>
      </div>

      {/* start: filter */}
      <div className='card-header pt-5'>
        <ListFilterLayout>
          <HookFormTextField
            control={control}
            name='code'
            placeholder='Search'
            defaultValue=''
            isListScreen
          />

          <HookFormSelectAntd
            name='category'
            placeholder='Category'
            control={control}
            size='large'
            classNameHeight='sapp-h-40'
          >
            {OPTIONS_DISCOUNT_TYPE.map((status) => (
              <Option key={status.label} value={status.value}>
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>

          <HookFormSelectAntd
            name='status'
            placeholder='Status'
            control={control}
            size='large'
            classNameHeight='sapp-h-40'
          >
            {OPTIONS_PROMOTION_CODE_STATUS.map((status) => (
              <Option key={status.label} value={status.value}>
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>

          <HookFormSelectAntd
            name='sortType'
            placeholder='Sort by'
            control={control}
            size='large'
            classNameHeight='sapp-h-40'
          >
            {FILTER_SELECTALL_SORTBY.map((status) => (
              <Option key={status.label} value={status.value}>
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>

          <RangeDateTimePicker
            control={control}
            name='rangeDate'
            allowClear={true}
            placeholder={['From date', 'To date']}
          />
        </ListFilterLayout>
      </div>
      <div className='card-header pt-5'>
        <div className='d-flex'>
          <SappFilterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={handleSubmit(handleFilter)}
            resetClick={handleResetFilter}
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
      </div>
      {/* end: filter */}

      <CombinedPromotionCodeTable
        data={data}
        isLoading={isLoading}
        fetchPromotionCodes={fetchPromotionCodes}
        getParams={getParams}
        handleChangeParams={handleChangeParams}
        checkedList={checkedList}
        toggleCheck={toggleCheck}
        toggleCheckAll={toggleCheckAll}
        isCheckedAll={isCheckedAll}
      />
    </SappModal>
  )
}

export default memo(CombinedPromotionCodeModal)
