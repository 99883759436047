import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { ISaleProducts, ISaleProductUpdate } from 'src/type/sales/products'

export class SaleProductAPI {
  static async getSaleProducts({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<ISaleProducts>> {
    return fetcher(`/payment-products?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static async createSaleProduct(data: ISaleProductUpdate): Promise<IResponse<ISaleProducts>> {
    return fetcher(`/payment-products`, {
      method: 'POST',
      data: data,
    })
  }

  static async updateSaleProduct(
    id: string,
    data: ISaleProductUpdate
  ): Promise<IResponse<ISaleProducts>> {
    return fetcher(`/payment-products/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static async deleteSaleProduct(id: string): Promise<IResponse<ISaleProducts>> {
    return fetcher(`/payment-products/${id}`, {
      method: 'DELETE',
    })
  }
}
