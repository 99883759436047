import { Select } from 'antd'
import { memo } from 'react'
import { Control, Controller } from 'react-hook-form'
import { KTIcon } from 'src/_metronic/helpers'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import SappLabel from 'src/components/base/label/SappLabel'

const { Option } = Select

interface IProps {
  placeholder?: string
  control: Control<any>
  defaultValue?: any
  name: string
  selectOptions: Array<{ name: string; image?: string; value: string }>
  label?: string
  guideline?: string[]
  required?: boolean
  disabled?: boolean
  classNameHeight?: 'min-h-45px' | 'min-h-40px'
  onClick?: any
  onDeselect?: (value: string) => void
}

const CustomTagsWithPlus = ({
  placeholder,
  control,
  defaultValue,
  name,
  selectOptions,
  label,
  guideline,
  required,
  classNameHeight = 'min-h-45px',
  disabled = false,
  onClick,
  onDeselect,
}: IProps) => {
  return (
    <>
      {label && <SappLabel label={label} required={required} />}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (
          <>
            <Select
              {...field}
              placeholder={placeholder}
              style={{ width: '100%' }}
              mode='multiple'
              size='large'
              className={`sapp-hook-form-select hookform-multi-select ${classNameHeight} sapp-h-auto`}
              status={error ? 'error' : ''}
              suffixIcon={<KTIcon iconName='plus' />}
              disabled={disabled}
              onClick={onClick}
              open={false}
              value={selectOptions?.map((option) => option.value) || []}
              onDeselect={onDeselect}
            >
              {selectOptions?.map((option) => (
                <Option key={option.value} value={option.value}>
                  <span className='mx-2'>{option.name}</span>
                </Option>
              ))}
            </Select>
            <GuidelineField guideline={guideline} />
            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        )}
      />
    </>
  )
}

export default memo(CustomTagsWithPlus)
